import React, { Suspense, lazy } from "react";
import { useRoutes } from "react-router-dom";
import AuthMaster from "../layouts/auth/AuthMaster";
import DashMaster from "../layouts/dashboard/DashMaster";
import PhraseMaster from "../layouts/auth/PhraseMaster";
import SettingMaster from "../layouts/dashboard/SettingMaster";
import WebMaster from "../layouts/website/WebMaster";
import GuestGuard from "../guards/GuestGuard";
import PhraseGuard from "../guards/PhraseGuard";
import AuthGuard from "../guards/AuthGuard";
import { authStore } from "../context/AuthProvider";
import BlockUser from "../components/common/BlockUser";
import KycPending from "../components/common/KycPending";
import DocumentationVersion2 from "../pages/Api-Doc/DocumentationVersion2";

// Lazy load components
const Login = lazy(() => import("../pages/auth/Login"));
const VerifyDevice = lazy(() => import("../pages/auth/VerifyDevice"));
const Register = lazy(() => import("../pages/auth/Register"));
const VerifyKeyPhrase = lazy(() => import("../pages/auth/VerifyKeyPhrase"));
const CreatePhrase = lazy(() => import("../pages/auth/CreatePhrase"));
const SelectSecretPhrase = lazy(() => import("../pages/auth/SelectSecretPhrase"));
const ForgotPassword = lazy(() => import("../pages/auth/ForgotPassword"));
const DashBoard = lazy(() => import("../layouts/dashboard/DashBoard"));
const NftDetails = lazy(() => import("../components/dash/NftDetails"));
const Send = lazy(() => import("../pages/send/Send"));
const SelectAddress = lazy(() => import("../pages/send/SelectAddress"));
const Receive = lazy(() => import("../pages/Receive/Receive"));
const Transaction = lazy(() => import("../pages/Transaction/Transaction"));
const Swap = lazy(() => import("../pages/Swap/Swap"));
const Api = lazy(() => import("../pages/Api/Api"));
const CreateApi = lazy(() => import("../pages/Api/CreateApi"));
const Payment = lazy(() => import("../pages/Payment/Payment"));
const Browser = lazy(() => import("../pages/Browser/Browser"));
const BrowserHistory = lazy(() => import("../pages/Browser/BrowserHistory"));
const Setting = lazy(() => import("../pages/Setting/Setting"));
const GoogleAuth = lazy(() => import("../pages/Setting/Security/GoogleAuth"));
const BackupPhrase = lazy(() => import("../pages/Setting/BackupPhrase/BackupPhrase"));
const SelectBackupPhrase = lazy(() => import("../pages/Setting/BackupPhrase/SelectBackupPhrase"));
const BackupCloud = lazy(() => import("../pages/Setting/BackupPhrase/BackupCloud"));
const BackupManually = lazy(() => import("../pages/Setting/BackupPhrase/BackupManually"));
const Support = lazy(() => import("../pages/Setting/Support/Support"));
const SupportDetail = lazy(() => import("../pages/Setting/Support/SupportDetail"));
const Language = lazy(() => import("../pages/Setting/Language/Language"));
const Kyc = lazy(() => import("../pages/Kyc/Kyc"));
const LandingPage = lazy(() => import("../pages/Website/LandingPage/LandingPage"));
const AboutUs = lazy(() => import("../pages/Website/AboutUs/AboutUs"));
const Swaps = lazy(() => import("../pages/Website/Swaps/Swaps"));
const StoreCrypto = lazy(() => import("../pages/Website/StoreCrypto/StoreCrypto"));
const CryptoDetail = lazy(() => import("../pages/Website/StoreCrypto/CryptoDetail"));
const Business = lazy(() => import("../pages/Website/Business/Business"));
const Faq = lazy(() => import("../pages/Website/Faq"));
const ContactSupport = lazy(() => import("../pages/Website/ContactSupport"));
const ContactUs = lazy(() => import("../pages/Website/ContactUs/ContactUs"));
const PrivacyPolicy = lazy(() => import("../pages/Website/PrivacyPolicy/PrivacyPolicy"));
const CookiePolicy = lazy(() => import("../pages/Website/CookiePolicy/CookiePolicy"));
const TermCondition = lazy(() => import("../pages/Website/TermandCondition/TermCondition"));
const AmlPolicy = lazy(() => import("../pages/Website/AML_policy/AmlPolicy"));
const SwitchWallet = lazy(() => import("../pages/Account/SwitchWallet"));
const Notification = lazy(() => import("../pages/notification"));
const ActiveCoins = lazy(() => import("../pages/auth/ActiveCoins"));
const WebLogin = lazy(() => import("../pages/auth/WebLogin"));
const Documentation = lazy(() => import("../pages/Api-Doc/Documentation"));

const Router = () => {
  const { isBlock, user } = authStore();

  const element = useRoutes([
    {
      path: "/login",
      element: (
        <GuestGuard>
          <AuthMaster />
        </GuestGuard>
      ),
      children: [
        { index: true, element: <WebLogin /> },
        { path: "verify-device", element: <VerifyDevice /> },
      ],
    },
    {
      path: "/register",
      element: (
        <GuestGuard>
          <AuthMaster />
        </GuestGuard>
      ),
      children: [
        { index: true, element: <Register /> },
        { path: "verify-device", element: <VerifyDevice /> },
        { path: "verify-key", element: <VerifyKeyPhrase /> },
      ],
    },
    {
      path: "/create-phrase",
      element: <PhraseMaster />,
      children: [
        { index: true, element: <CreatePhrase /> },
        { path: "select", element: <SelectSecretPhrase /> },
        { path: "verify-key", element: <VerifyKeyPhrase /> },
      ],
    },
    {
      path: "/forgot-password",
      element: <AuthMaster />,
      children: [{ index: true, element: <ForgotPassword /> }],
    },
    {
      path: "/dashboard",
      element: (
        <AuthGuard>
          <DashMaster />
        </AuthGuard>
      ),
      children: [
        { index: true, element: isBlock == 1 ? <BlockUser /> : <DashBoard /> },
        { path: "nft-detail", element: isBlock == 1 ? <BlockUser /> : <NftDetails /> },
        { path: "send", element: isBlock == 1 ? <BlockUser /> : <Send /> },
        { path: "select-address", element: isBlock == 1 ? <BlockUser /> : <SelectAddress /> },
        { path: "receive", element: isBlock == 1 ? <BlockUser /> : <Receive /> },
        { path: "transactions", element: isBlock == 1 ? <BlockUser /> : <Transaction /> },
        { path: "swap", element: isBlock == 1 ? <BlockUser /> : <Swap /> },
        { path: "api", element: isBlock == 1 ? <BlockUser /> : user?.isKyc == 0 ? <KycPending /> : <Api /> },
        { path: "create-api", element: isBlock == 1 ? <BlockUser /> : <CreateApi /> },
        { path: "payment", element: isBlock == 1 ? <BlockUser /> : user?.isKyc == 0 ? <KycPending /> : <Payment /> },
        { path: "browser", element: isBlock == 1 ? <BlockUser /> : <Browser /> },
        { path: "browser-history", element: isBlock == 1 ? <BlockUser /> : <BrowserHistory /> },
        { path: "active-coins", element: isBlock == 1 ? <BlockUser /> : <ActiveCoins /> },
        { path: "switch-account", element: isBlock == 1 ? <BlockUser /> : <SwitchWallet /> },
        { path: "notification", element: isBlock == 1 ? <BlockUser /> : <Notification /> },
        { path: "user-blocked", element: <BlockUser /> },
        {
          path: "setting",
          element: isBlock == 1 ? <BlockUser /> : <SettingMaster />,
          children: [
            { index: true, element: <Setting /> },
            { path: "google-auth", element: <GoogleAuth /> },
            { path: "backup-phrase", element: <BackupPhrase /> },
            { path: "select-backup-phrase", element: <SelectBackupPhrase /> },
            { path: "backup-cloud", element: <BackupCloud /> },
            { path: "backup-manually", element: <BackupManually /> },
            { path: "support", element: <Support /> },
            { path: "support-detail", element: <SupportDetail /> },
            { path: "language", element: <Language /> },
          ],
        },
        { path: "kyc", element: isBlock == 1 ? <BlockUser /> : <Kyc /> },
      ],
    },
    {
      path: "/",
      element: <WebMaster />,
      children: [
        { path: "/", element: <LandingPage /> },
        { path: "/about-us", element: <AboutUs /> },
        { path: "/swap", element: <Swaps /> },
        { path: "/store-crypto", element: <StoreCrypto /> },
        { path: "/crypto-detail", element: <CryptoDetail /> },
        { path: "/business", element: <Business /> },
        { path: "/faq", element: <Faq /> },
        { path: "/api-documentation", element: <Documentation /> },
        { path: "/v1/api-documentation", element: <DocumentationVersion2 /> },
        { path: "/support", element: <ContactSupport /> },
        { path: "/contact-us", element: <ContactUs /> },
        { path: "/privacy-policy", element: <PrivacyPolicy /> },
        { path: "/cookie-policy", element: <CookiePolicy /> },
        { path: "/term-condition", element: <TermCondition /> },
        { path: "/aml-policy", element: <AmlPolicy /> },
      ],
    },
  ]);

  return <Suspense fallback={<div>Loading...</div>}>{element}</Suspense>;
};

export default Router;
