import React from 'react';
import IcnBlockUser from '../../assets/svg/IcnBlockUser';
import { Button } from '../ui/Button';
import { useNavigate } from 'react-router-dom';

const BlockUser = () => {
    const navigate =useNavigate()
    const navigateTo = () =>{
        navigate('/support')
    }
    return (
        <div className="w-full bg-dark">
            <div className='max-w-[300px] mx-auto h-[calc(100vh-80px)] text-center flex items-center justify-center flex-col gap-[8px]'>
                <IcnBlockUser className='h-[100px] w-[100px]' />
                <h6 className='text-white font-600'>Blocked</h6>
                <p className='text-white font-500 capitalize text-[14px]'>You're blocked by Oppi wallet. For More Info contact our support Team</p>
                <Button className="w-full py-[8px]" onClick={navigateTo}>Get Support</Button>
            </div>
        </div>
    );
}

export default BlockUser;
