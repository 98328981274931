import React from 'react'
import { cn } from '../../lib/utils'

const Loader = ({ className }) => {
  return (
    <div className={cn("flex justify-center h-full items-center bg-card", className)}>
      <div class='flex space-x-2 justify-center items-center mt-12 max-w-max'>
        <span class='sr-only'>Loading...</span>
        <div class='h-8 w-8 bg-linear-horizontal rounded-full animate-bounce [animation-delay:-0.3s]'></div>
        <div class='h-8 w-8 bg-linear-horizontal rounded-full animate-bounce [animation-delay:-0.15s]'></div>
        <div class='h-8 w-8 bg-linear-horizontal rounded-full animate-bounce'></div>
      </div>
    </div>
  )
}

export default Loader