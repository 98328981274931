import React, { useEffect, useState } from "react";
import WebHeader from "./WebHeader";
import BackDrop from "../../components/common/BackDrop";
import { Outlet, useLocation } from "react-router-dom";
import WebFooter from "./WebFooter";
import Aos from "aos";
import 'aos/dist/aos.css';
import Loader from "../../components/ui/Loader";

const WebMaster = () => {
  const [toogleMenu, setToogleMenu] = useState(false);
  const { pathname } = useLocation();
  const [load, setLoad] = useState(true)
  useEffect(() => {
    setToogleMenu(false);
  }, [pathname]);
  useEffect(() => {
    setTimeout(() => {
      Aos.init();
    }, 1000);
  }, [])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  })

  useEffect(() => {
    setTimeout(() => {
      setLoad(false)
    }, 1500);
  }, [])

  return (
    <>
      {toogleMenu ? (
        <BackDrop toogleMenu={toogleMenu} setToogleMenu={setToogleMenu} />
      ) : null}
      <>
        {
          load ?
          <div className="h-screen">
            <Loader />
          </div> :
            <>
              <WebHeader toogleMenu={toogleMenu} setToogleMenu={setToogleMenu} />
              <main className="overflow-hidden">
                <div className="lg:pt-[99px] pt-[86px]">
                  <Outlet />
                </div>
              </main>
              <WebFooter />
            </>
        }
      </>
    </>
  );
};

export default WebMaster;
