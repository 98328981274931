import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Button } from '../ui/Button'
import { cn } from '../../lib/utils'

const SuccessMessageModal = ({ open, handleClose, message, message2, messageClass }) => {

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform rounded-[30px] bg-white overflow-hidden border border-light-100 shadow-xl transition-all">
                < div className='dialog-body p-8' >
                  {/* <Lottie
                    options={defaultOptions}
                    height={190}
                    width={190}
                  /> */}
                  <img src={require("../../assets/gif/success.gif")} className='w-[190px] mx-auto' alt="" />
                  <p className={cn('w-[60%] text-center text-black mx-auto text-20 font-600 mb-12', messageClass)}>{message}</p>
                  <p className={cn('w-[60%] text-center text-light-600 mx-auto text-14 font-400 !mb-12', messageClass)}>{message2}</p>
                  <Button size={"sm"} className="w-full" onClick={handleClose}>Done</Button>
                </div >
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )


}

export default SuccessMessageModal
