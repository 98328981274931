import { useEffect } from 'react';
import './App.css';
import { authStore } from './context/AuthProvider';
import i18n from './i18n';
import Router from './routes';

function App() {
  const { language } = authStore();
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language])

  return (
    <Router />
  );
}

export default App;
