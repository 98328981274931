import React from 'react'

const IconLink = (props) => {
  return (
    <svg {...props} viewBox="0 0 28 28" fill="none">
      <path d="M11.8386 20.6329L8.85736 23.6141C7.62141 24.8501 5.61989 24.8501 4.38497 23.6143C3.14981 22.3792 3.14981 20.3775 4.38473 19.1426L10.3482 13.1791C11.5831 11.9441 13.5848 11.9441 14.8197 13.1791C15.2314 13.5908 15.8989 13.5908 16.3106 13.1791C16.7222 12.7674 16.7222 12.0999 16.3106 11.6883C14.2523 9.62996 10.9157 9.62996 8.85736 11.6883L2.89395 17.6517C0.835644 19.71 0.835644 23.0466 2.89395 25.1049C4.952 27.1643 8.28885 27.1643 10.3482 25.1049L13.3295 22.1237C13.7411 21.712 13.7411 21.0445 13.3295 20.6328C12.9178 20.2212 12.2502 20.2212 11.8386 20.6329Z" fill="#8BFE63" />
      <path d="M25.1059 2.89334C23.0476 0.835034 19.7099 0.835034 17.6516 2.89334L14.0748 6.47014C13.6632 6.88181 13.6632 7.5493 14.0748 7.96097C14.4865 8.37264 15.154 8.37264 15.5657 7.96097L19.1425 4.38417C20.3774 3.1492 22.3801 3.1492 23.6151 4.38417C24.85 5.61908 24.85 7.6208 23.6151 8.85572L17.056 15.4148C15.8211 16.6498 13.8194 16.6498 12.5845 15.4148C12.1728 15.0031 11.5053 15.0031 11.0937 15.4148C10.682 15.8265 10.682 16.494 11.0937 16.9057C13.152 18.964 16.4886 18.964 18.5469 16.9057L25.1059 10.3466C27.1642 8.28829 27.1642 4.95164 25.1059 2.89334Z" fill="#8BFE63" />
    </svg>
  )
}

export default IconLink