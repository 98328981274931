function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_PATH = "/user";


const USER_API_URL = {
    register: path(ROOTS_PATH, "/register"),
    userVerified: path(ROOTS_PATH, "/checkVerifyStatus"),
    uploadUserImage: path(ROOTS_PATH, "/upload/image"),
    updateProfile: path(ROOTS_PATH, "/edit-profile"),
    sendEmailToVerifyLogin: path(ROOTS_PATH, "/sendLoginMail"),
    login: path(ROOTS_PATH, "/login"),
    storeKey: path(ROOTS_PATH, "/storeKey"),
    logout: path(ROOTS_PATH, "/logout"),
    userProfile: path(ROOTS_PATH, "/get-profile"),
    refreshToken: path(ROOTS_PATH, "/refreshToken"),
    addContactUs : path(ROOTS_PATH, "/addContact"),
    getCms : path(ROOTS_PATH, "/getCmsPages"),
    getNotification : path(ROOTS_PATH, "/getNotification"),
    generateKycToken : path(ROOTS_PATH, "/kyc/generateAccessToken"),
    createKycApplicant : path(ROOTS_PATH, "/kyc/createKycApplicant"),
}

const WALLET_API_URL = {
    getToken: path(ROOTS_PATH, "/getToken"),
    getCoin : path(ROOTS_PATH, "/getCoin"),
   
    getVaultAccount: path(ROOTS_PATH, "/getVaultAccountsByUser"),
    addCustomToken: path(ROOTS_PATH, "/addCustomToken"),
    activeInactiveToken: path(ROOTS_PATH, "/activeInactiveToken"),
    getActiveToken: path(ROOTS_PATH, "/getActiveToken"),
    getCustomToken : path(ROOTS_PATH, "/getOnToken"),
    
}

const RECEIVE_API_URL = {
    getAccestAddress: path(ROOTS_PATH, "/getAssetAddress")

}

const SEND_API_URL = {
    getAddress: path(ROOTS_PATH, "/getAddress"),
    getAddressOrigin: path(ROOTS_PATH, "/getAddressOrigin"),
    getCoin: path(ROOTS_PATH, "/getCoin"),
    addAddress: path(ROOTS_PATH, "/addAddress"),
    addTransaction: path(ROOTS_PATH, "/addTransaction"),
    validateAddress: path(ROOTS_PATH, "/validateAddress"),
    getDataFromAddress: path(ROOTS_PATH, "/getDataFromAddress"),
}

const SUPPORT_TICKET_API_URL = {
    createTicket: path(ROOTS_PATH, "/sendSupportTicket"),
    getTicket: path(ROOTS_PATH, "/getTickets"),
    sendMessage: path(ROOTS_PATH, "/sendMessage"),
    getMessages: path(ROOTS_PATH, "/getMessagesWeb"),
    getSupportTicketById: path(ROOTS_PATH, "/getSupportTicketById"),
}

const TRANSACTION_API_URL = {
    transactionList: path(ROOTS_PATH, "/transactionList"),
    nftList: path(ROOTS_PATH, "/listNFTs"),
    getCurrencyCode: path(ROOTS_PATH, "/getCurrencyCode")
}

const PAYMENT_LINK_API_URL = {
    createPaymentLink: path(ROOTS_PATH, "/addPaymentLink"),
    paymentLinkList: path(ROOTS_PATH, "/getPaymentLinkWeb"),
    totalPayments: path(ROOTS_PATH, "/totalPayments"),
    getCurrentUtcTime: path(ROOTS_PATH, "/getCurrentUtcTime"),
    currentTime : path(ROOTS_PATH, "/getCurrentUtcTime")
}

const APP_API_URL = {
    createApp: path(ROOTS_PATH, "/createApp"),
    appList: path(ROOTS_PATH, "/appList"),
    apiRequestList: path(ROOTS_PATH, "/apiRequestList"),
    apiCallbackList: path(ROOTS_PATH, "/apiCallbackList"),
    updateApp: path(ROOTS_PATH, "/editApp"),
    deleteApp: path(ROOTS_PATH, "/deleteAPP"),
}


const AUTH_API_URL = {
    // generate2FAQr: path(ROOTS_PATH, "/generate-google2fa"),
    // new
    handleTwoFactorAuthWeb: path(ROOTS_PATH, "/handleTwoFactorAuthWeb"),
    send2FaMail: path(ROOTS_PATH, "/send2fa-mail"),
    verify2faOrEmail: path(ROOTS_PATH, "/emailOr2faVerifyWeb"),
    // old
    google2faVerify: path(ROOTS_PATH, "/google2fa-check"),
    sendEmailAuth: path(ROOTS_PATH, "/send-mail-authentication"),
    verifyEmail: path(ROOTS_PATH, "/verify-email"),
    enableVerifications: path(ROOTS_PATH, "/authenticationType"),
    kycSessionCreate: path(ROOTS_PATH, "/kyc/create-session"),
    checkSessionStatus: path(ROOTS_PATH, "/kyc/check-status")
}

const SWAP_API_URL = {
    getChangeAmount: path(ROOTS_PATH, "/changeAmount"),
    swapCoin: path(ROOTS_PATH, "/swapCoin"),
    transactionEstimateFee: path(ROOTS_PATH, "/transactionEstimateFee"),
    getToTokenList: path(ROOTS_PATH, "/getPairs"),
}

const HOME_API_URL = {
    blogList :path(ROOTS_PATH, "/listBlog"),
}
const FAQ_URL = {
    getFaqs: path(ROOTS_PATH, "/getFaq"),
  };

export {
    USER_API_URL,
    WALLET_API_URL,
    RECEIVE_API_URL,
    SEND_API_URL,
    SUPPORT_TICKET_API_URL,
    TRANSACTION_API_URL,
    PAYMENT_LINK_API_URL,
    APP_API_URL,
    AUTH_API_URL,
    SWAP_API_URL,
    HOME_API_URL,
    FAQ_URL
}