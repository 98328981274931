// Import useContext from react
import { createContext, useEffect, useState } from "react";
import socketio from "socket.io-client";
import { authStore } from "./AuthProvider";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { useSnackbar } from "notistack";

// Create the context
export const AuthSocketContext = createContext();

const SocketUrl = process.env.REACT_APP_SOCKET_URL;

const AuthSocketContextProvider = ({ children }) => {
  const [connected, setConnected] = useState(false);
  const [socketClient, setSocketClient] = useState();
  const [pathName, setPathName] = useState({});
  const [authKey, setAuthKey] = useState();
  const [authUser, setAuthUser] = useState();
  const [currencyRate, setCurrencyRate] = useState();
  const [confirmSend, setConfirmSend] = useState(null)
  const [confirmSendNft, setConfirmSendNft] = useState(null)
  const { user, set, encKey, isSendPayment, isSwapPayment, userCurrencyRate } = authStore()
  const [approveReject, setApproveReject] = useState();
  const [approveRejectSwap, setApproveRejectSwap] = useState();
  const [switchWalletAuthKey, setSwitchWalletAuthKey] = useState(null)
  const [isLogin, setIsLogin] = useState(0)
  const { enqueueSnackbar } = useSnackbar()
  const [confirmSwap, setConfirmSwap] = useState(null)

  // Extract setAccessToken, setUser, setHasEncyptionWordKey, setIsAuthenticated from authStore
  const setAccessToken = authStore((state) => state.setAccessToken);
  const setIsAuthenticated = authStore((state) => state.setIsAuthenticated);
  const navigate = useNavigate();




  useEffect(() => {
    if (authKey) {
      const initSocket = () => {
        const client = socketio(SocketUrl, {
          query: {
            qrKey: authKey,
          },
          transports: ["websocket"],
          upgrade: true,
          reconnection: false,
          autoConnect: false,
          forceNew: true,
        });
        client.on('connect', function (data) {
          setConnected(true);
          // LOGIN ON EVENT
          client.on('linkProcessStarting', function (data) {
          })

          client.on('getLinkDevice', function (data) {
            if (data?.encryptedKey == encKey) {
              enqueueSnackbar("Please Change Wallet from app first", {
                variant: "success",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                autoHideDuration: 2000,
              });
              return
            } else {
              // if (data?.isBlock == 0) {
              set({ isBlock: data?.isBlock })
              if (isLogin == 1) {
                set({ encKey: data?.encryptedKey })
                setAccessToken(data?.accessToken)
                setIsAuthenticated(true)
                // navigate('/dashboard')
                setIsLogin(0)
              } else {
                set({ walletName: data?.walletName })
                set({ encKey: data?.encryptedKey })
                setAccessToken(data?.accessToken)
                const message = 'Your wallet has been changed to' + ' ' + data?.walletName + ' ' + 'wallet!'
                enqueueSnackbar(message, {
                  variant: "success",
                  anchorOrigin: { vertical: "top", horizontal: "right" },
                  autoHideDuration: 2000,
                });
              }
              navigate('/dashboard')

            }

          })

        });
        setSocketClient(client);
        if (!client.connected) {
          console.log('Connecting...');
          setConnected(true);
          client.connect();
        }
      };
      initSocket();
      // Cleanup function to disconnect the socket when the component unmounts
      return () => socketClient?.disconnect();

    }
  }, [authKey]);



  useEffect(() => {
    if (authUser || user || confirmSend || confirmSwap) {
      const initSocket = () => {
        const client = socketio(SocketUrl, {
          query: {
            userId: user?._id,
          },
          transports: ["websocket"],
          upgrade: true,
          reconnection: false,
          autoConnect: false,
          forceNew: true,
        });


        client.on('connect', function () {
          console.log('Socket connected');
          const userData = {
            userId: authUser?._id,
          };
          // Emit 'requestCurrencyRate' event with userData
          client.emit('requestCurrencyRate', userData, function () {
            console.log('requested');
          });

          client.on('getCurrencyRate', function (data) {
            // Handle the response from 'getCurrencyRate'
            setCurrencyRate(data);
            set({ userCurrencyRate: data })
          });
          client.on('approveRejectTransaction', function (data) {
            // Handle the response from 'getCurrencyRate'
            if (data?.transactionType == 2) {
              if (isSwapPayment) {
                setConfirmSwap(null)
                setApproveRejectSwap(data)
              }
            } else if (data?.transactionType == 1) {
              if (isSendPayment == true) {
                setConfirmSend(null)
                setApproveReject(data)
              }
            } else if (data?.transactionType == 3) {
              // if (isSendPayment == true) {
              setConfirmSendNft(null)
              setApproveReject(data)
              // }
            }
          });

          if (confirmSend) {
            set({ isSendPayment: true })
            client.emit('transactionConfirmation', confirmSend, (data) => {
              console.log('Event emmited');
            });
            setConfirmSend(null)
          }
          if (confirmSwap) {
            set({ isSwapPayment: true })
            client.emit('swapConfirmation', confirmSwap, (data) => {
              console.log('Event emmited', confirmSwap);
            });
            setConfirmSwap(null)
          }
          if (confirmSendNft) {
            console.log('call');
            set({ isNftSend: true })
            client.emit('nftConfirmation', confirmSendNft, (data) => {
              console.log('Event emmited', confirmSendNft);
            });
            setConfirmSendNft(null)
          }

          client.on('blockUnblockUser', function (data) {
            if (data) {
              set({ isBlock: data?.isBlock })
            }
          });

          client.on('kycVerification', function (data) {
            console.log('kycVerification', data);
            if (data?.status) {
              set((state) => ({
                user: {
                  ...state.user,
                  kycStatus: data?.status,
                  isKyc: data?.status == 6 ? 1 : 0
                },
              }));
            }
            if (data?.status == 6) {
              navigate('/dashboard')
            }

          })
        });

        if (!client.connected) {
          client.connect();
        }

        return () => {
          client?.disconnect();
        };
      };

      initSocket();
    }
  }, [authUser, user, confirmSend, confirmSwap, confirmSendNft]);


  return (
    <AuthSocketContext.Provider value={{ connected, socketClient, setPathName, setAuthKey, authKey, setAuthUser, authUser, currencyRate, setCurrencyRate, setConfirmSend, confirmSend, approveReject, setApproveReject, setSwitchWalletAuthKey, switchWalletAuthKey, setIsLogin, setConfirmSwap, confirmSwap, setApproveRejectSwap, approveRejectSwap, confirmSendNft, setConfirmSendNft }}>
      {children}
    </AuthSocketContext.Provider>
  );
};

export default AuthSocketContextProvider;
