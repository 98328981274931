import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import IconAttention from "../../assets/svg/IconAttention";
import { Helmet } from "react-helmet-async";
import IconCopy from "../../assets/svg/IconCopy";
import IcnJs from "../../assets/svg/IcnJs";
import IcnPython from "../../assets/svg/IcnPython";
import IcnPhp from "../../assets/svg/IcnPhp";
import IcnNode from "../../assets/svg/IcnNode";
import CustomSelect from "../../components/ui/CustomSelect";
import IcnDot from "../../assets/svg/IcnDot";
import IconDown from "../../assets/svg/IconDown";
import DownloadZip from "../../assets/zip/oppiwallet_external.zip";
import { axiosInstance } from "../../api/axiosInstance";

const language = [
    {
        id: 1,
        title: 'Shell',
        icon: <IcnJs className='h-[20px] w-[20px]' />,
    },
    {
        id: 2,
        title: 'Python',
        icon: <IcnPython className='h-[20px] w-[20px]' />,
    },
    {
        id: 3,
        title: 'PHP',
        icon: <IcnPhp className='h-[20px] w-[20px]' />,
    },
    {
        id: 4,
        title: 'NodeJS',
        icon: <IcnNode className='h-[20px] w-[20px]' />,
    }
]
const resList = [
    {
        icon: <IcnDot className="h-[20px] w-[20px] text-green" />,
        status: '200'
    },
    {
        icon: <IcnDot className="h-[20px] w-[20px] text-red" />,
        status: '400'
    }
]

const DocumentationVersion2 = () => {
    const [toogleMenu, setToogleMenu] = useState(false);
    const [copy, setCopy] = useState(false);
    const [open, setOpen] = useState(false);
    const [clipboard, setClipboard] = useState(false);
    const [copyResponse, setCopyResponse] = useState(false);
    const [selectValue, setSelectValue] = useState(resList[0]);
    const [lang, setLang] = useState(language[0]);
    const [id, setId] = useState();
    const { pathname } = useLocation();
    const apiLink = '/v1/createTransactionRequest';
    useEffect(() => {
        setToogleMenu(false);
    }, [pathname]);
    const [widthSize, setWidthSize] = useState(0);
    const resizeWindow = () => {
        setWidthSize(window.innerWidth);
    };
    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);

    let mobileClass = `fixed transition-all duration-300 ${toogleMenu ? "start-0" : "-start-[100%]"
        } ease-in-out h-screen bg-black max-w-[320px] w-full top-0 p-12 pt-24 z-[998]`;

    const handleCopy = (ids, key) => {
        setId(ids);
        setCopy(true);
        navigator?.clipboard?.writeText(key)
    }

    useEffect(() => {
        setTimeout(() => {
            setCopy(false)
        }, 2000);
    }, [copy])

    const scrollToSection = (sectionId) => {
        const abc = document.getElementsByClassName('content-section')[0];
        const section = document.getElementById(sectionId);
        if (section) {
            abc?.scrollTo({
                top: section.offsetTop - 100,
                behavior: "smooth",
            });
        }
    };

    const scrollRigntSection = (sectionId) => {
        const abc = document.getElementsByClassName('right-side')[0];
        const section = document.getElementById(sectionId);
        if (section) {
            abc?.scrollTo({
                top: section.offsetTop - 120,
                behavior: "smooth",
            });
        }
    };

    const copyCode = (ids, codeId) => {
        setId(ids)
        setClipboard(true)
        // const codeContent = document.querySelector('.code-content');
        const codeContent = document.querySelector(`#${codeId}`);
        console.log(codeContent);
        const codeText = codeContent.innerText;

        const textarea = document.createElement('textarea');
        textarea.value = codeText;
        document.body.appendChild(textarea);

        textarea.select();

        document.execCommand('copy');

        document.body.removeChild(textarea);
    }

    const responseCopy = (ids, resId) => {
        setId(ids)
        setCopyResponse(true)
        // const codeContent = document.querySelector('.response-content');
        const codeContent = document.querySelector(`#${resId}`);
        const codeText = codeContent.innerText;

        const textarea = document.createElement('textarea');
        textarea.value = codeText;
        document.body.appendChild(textarea);

        textarea.select();

        document.execCommand('copy');

        document.body.removeChild(textarea);
    }

    useEffect(() => {
        setTimeout(() => {
            setClipboard(false);
            setCopyResponse(false)
        }, 2000);
    }, [clipboard, copyResponse])

    const [scrollPositions, setScrollPositions] = useState({});
    const refs = {
        left: useRef({}),
        right: useRef({})
    };

    // useEffect(() => {
    //   const handleScroll = (side, id) => {
    //     console.log('...................');
    //     return () => {
    //       const leftElement = refs.left.current[id];
    //       const rightElement = refs.right.current[id];
    //       console.log('leftElement', leftElement);
    //       console.log('rightElement', rightElement);

    //       if (leftElement && rightElement) {
    //         const leftScroll = leftElement.scrollTop;
    //         const rightScroll = rightElement.scrollTop;

    //         console.log('leftElement>>>>', leftElement);
    //       console.log('rightElement>>>>', rightElement);

    //         setScrollPositions(prevState => ({
    //           ...prevState,
    //           [id]: { left: leftScroll, right: rightScroll }
    //         }));

    //         // Synchronize scroll positions
    //         for (const key in refs.left.current) {
    //           console.log('key', key);
    //       console.log('refs.left.current', refs.left.current);
    //           if (key !== id) {
    //             refs.left.current[key].scrollTop = 0;
    //             refs.right.current[key].scrollTop = 0;
    //           }
    //         }
    //       }
    //     };
    //   };


    // for (const key in refs.left.current) {
    //   const leftElement = refs.left.current[key];
    //   const rightElement = refs.right.current[key];

    //   leftElement.addEventListener('scroll', handleScroll('left', key));
    //   rightElement.addEventListener('scroll', handleScroll('right', key));
    // }

    // return () => {
    //   for (const key in refs.left.current) {
    //     const leftElement = refs.left.current[key];
    //     const rightElement = refs.right.current[key];

    //     leftElement.removeEventListener('scroll', handleScroll('left', key));
    //     rightElement.removeEventListener('scroll', handleScroll('right', key));
    //   }
    // };
    // }, );

    //     useEffect(() => {
    //       document.querySelector('.right-side').addEventListener('scroll', function() {
    //     var mainDiv = this;
    //     var children = mainDiv.children;
    //     var visibleChildId;

    //     for (var i = 0; i < children.length; i++) {
    //       var child = children[i];
    //       var rect = child.getBoundingClientRect();

    //       // Check if the child is in the view
    //       console.log('id', rect);
    //       if (rect.top === 0 || rect.bottom >= 500) {
    //             visibleChildId = child.id;
    //             if(child.id == 'right-supported-currency'){
    //               scrollToSection('supported-currency')
    //             }
    //             if(child.id == 'right-deposit'){
    //               scrollToSection('deposit')
    //             }
    //             if(child.id == 'right-withdraw'){
    //               scrollToSection('withdraw')
    //             }
    //             if(child.id == 'right-all-transactions'){
    //               scrollToSection('all-transactions')
    //             }
    //             if(child.id == 'right-single-transaction'){
    //               scrollToSection('single-transaction')
    //             }
    //             break;
    //         }
    //     }

    //     console.log("Visible child ID:", visibleChildId);
    // });


    //     }, [])


    useEffect(() => {
        document.querySelector('.content-section').addEventListener('scroll', function () {
            var mainDiv = this;
            var children = mainDiv.children;
            var visibleChildId;

            for (var i = 0; i < children.length; i++) {
                var child = children[i];
                var rect = child.getBoundingClientRect();

                // Check if the child is in the view
                console.log('id', rect);
                if (rect.top === 0 || rect.bottom >= 400) {
                    // if (rect.top === 200 || rect.bottom <= 500) {
                    visibleChildId = child.id;
                    if (child.id == 'supported-currency') {
                        scrollRigntSection('right-supported-currency')
                    }
                    if (child.id == 'deposit') {
                        scrollRigntSection('right-deposit')
                    }
                    if (child.id == 'withdraw') {
                        scrollRigntSection('right-withdraw')
                    }
                    if (child.id == 'autoWithdraw') {
                        scrollRigntSection('right-withdraw-auto')
                    }
                    if (child.id == 'all-transactions') {
                        scrollRigntSection('right-all-transactions')
                    }
                    if (child.id == 'single-transaction') {
                        scrollRigntSection('right-single-transaction')
                    }
                    if (child.id == 'get-balance') {
                        scrollRigntSection('right-get-balance')
                    }
                    break;
                }
            }

            console.log("Visible child ID:", visibleChildId);
        });


    }, [])

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>API Access | Oppi Wallet
                </title>
                <meta name="description" content="Oppi Wallet documentation for APIs helps developers to automate various services through their own application." />
                <link rel="canonical" href="https://oppiwallet.com/v1/api-documentation" />

                <meta property="og:title" content="API Access | Oppi Wallet" />
                <meta property="og:description"
                    content="Oppi Wallet documentation for APIs helps developers to automate various services through their own application." />
                <meta property="og:url" content="https://oppiwallet.com/v1/api-documentation" />
            </Helmet>
            {/* <header className="bg-black border-b border-b-light-500 py-9 fixed top-0 w-full z-[99]">
        <div className="px-[80px] flex justify-between items-center">
          <Link to={"/"}>
            <img
              src={require("../../assets/images/WebLogo.svg").default}
              className="w-[175px] h-[40px]"
              alt=""
            />
          </Link>
          <div className={`${widthSize > 992 ? "" : mobileClass}`}>
            <button
              className="lg:hidden absolute top-[24px] end-[20px]"
              onClick={() => setToogleMenu(false)}
            >
              <IconClose className="text-white w-[30px] h-[30px]" />

            </button>
            <div className="sidebar bg-white rounded-[10px] w-full h-full max-w-[320px] p-10 overflow-y-auto theme-scrollbar lg:hidden mt-5">
              <h4 className="text-20"><a href="#introduction">Introduction</a></h4>
              <ul className="p-8 flex flex-col gap-4">
                <li className="text-16 font-500 text-black/80 hover:text-black cursor-pointer"><a href="#key-setup">API Key Setup</a></li>
                <li className="text-16 font-500 text-black/80 hover:text-black cursor-pointer"><a href="#key-restrication">API Key Restrictions</a></li>
                <li className="text-16 font-500 text-black/80 hover:text-black cursor-pointer"><a href="#enablibg-account">Enabling Accounts</a></li>
                <li className="text-16 font-500 text-black/80 hover:text-black cursor-pointer"><a href="#postman-collection">Postman Collections</a>s</li>
                <li className="text-16 font-500 text-black/80 hover:text-black cursor-pointer"><a href="#contact-us">Contact Us</a></li>
              </ul>
              <h4 className="text-20"><a href="#transaction">Transaction</a></h4>
              <ul className="p-8 flex flex-col gap-4">
                <li className="text-16 font-500 text-black/80 hover:text-black cursor-pointer"><a href="#withdraw-collection">Withdraw Transaction</a></li>
                <li className="text-16 font-500 text-black/80 hover:text-black cursor-pointer"><a href="#Auto-withdraw-collection">Auto Withdraw Transaction</a></li>
              </ul>
            </div>
          </div>
          <button
            className="bg-none border-none outline-none lg:hidden block"
            onClick={() => setToogleMenu(!toogleMenu)}
          >
            <IconMenu className="w-[24px] h-[24px] text-white" />
          </button>
        </div>
      </header> */}
            <div className="xxl:h-[calc(100vh-86px)] h-auto p-8 bg-[#b5b5b5]">
                <div className="flex h-full gap-8">
                    <div
                        className="sidebar bg-white rounded-[10px] w-full h-full max-w-[320px] p-10 overflow-y-auto theme-scrollbar max-lg:hidden flex-shrink-0">
                        <h4 className="text-20"><a onClick={() => scrollToSection("introduction")}>Introduction</a></h4>
                        <ul className="p-8 flex flex-col gap-4">
                            <li className="text-16 font-500 text-black/75 hover:text-greenweb cursor-pointer"><a
                                onClick={() => scrollToSection("key-setup")}>API Key Setup</a></li>
                            <li className="text-16 font-500 text-black/75 hover:text-greenweb cursor-pointer"><a
                                onClick={() => scrollToSection("key-restrication")}>API Key Restrictions</a></li>
                            <li className="text-16 font-500 text-black/75 hover:text-greenweb cursor-pointer"><a
                                onClick={() => scrollToSection("auth-Key")}>What is Auth Key?</a></li>
                            <li className="text-16 font-500 text-black/75 hover:text-greenweb cursor-pointer"><a
                                onClick={() => scrollToSection("enc-Key")}>What is Enc Key?</a></li>
                            <li className="text-16 font-500 text-black/75 hover:text-greenweb cursor-pointer"><a
                                onClick={() => scrollToSection("jwt-token")}>Generate JWT Token</a></li>
                            <li className="text-16 font-500 text-black/75 hover:text-greenweb cursor-pointer"><a
                                onClick={() => scrollToSection("enablibg-account")}>Enabling Accounts</a></li>
                            {/* <li className="text-16 font-500 text-black/75 hover:text-greenweb cursor-pointer"><a onClick={() => scrollToSection("postman-collection")}>Postman Collections</a>s</li> */}
                            <li className="text-16 font-500 text-black/75 hover:text-greenweb cursor-pointer"><a
                                onClick={() => scrollToSection("contact-us")}>Contact Us</a></li>
                        </ul>
                        <h4 className="text-20"><a onClick={() => scrollToSection("api")}>API</a></h4>
                        <ul className="p-8 flex flex-col gap-4">
                            <li className="text-16 font-500 text-black/80 hover:text-greenweb cursor-pointer"><a
                                onClick={() => { scrollToSection("supported-currency"); scrollRigntSection("right-supported-currency") }}>Supported Currencies</a></li>
                            <li className="text-16 font-500 text-black/80 hover:text-greenweb cursor-pointer"><a
                                onClick={() => { scrollToSection("deposit"); scrollRigntSection("right-deposit") }}>Deposit</a></li>
                            <li className="text-16 font-500 text-black/80 hover:text-greenweb cursor-pointer"><a
                                onClick={() => { scrollToSection("withdraw"); scrollRigntSection("right-withdraw") }}>Withdraw (Manual)</a></li>
                            <li className="text-16 font-500 text-black/80 hover:text-greenweb cursor-pointer"><a
                                onClick={() => { scrollToSection("autoWithdraw"); scrollRigntSection("right-withdraw-auto") }}>Withdraw (Auto)</a></li>
                            <li className="text-16 font-500 text-black/80 hover:text-greenweb cursor-pointer"><a
                                onClick={() => { scrollToSection("all-transactions"); scrollRigntSection("right-all-transactions") }}>All Transactions</a></li>
                            <li className="text-16 font-500 text-black/80 hover:text-greenweb cursor-pointer"><a
                                onClick={() => { scrollToSection("single-transaction"); scrollRigntSection("right-single-transaction") }}>Transaction</a></li>
                            <li className="text-16 font-500 text-black/80 hover:text-greenweb cursor-pointer"><a
                                onClick={() => { scrollToSection("single-balance"); scrollRigntSection("right-get-balance") }}>Get Balance</a></li>

                        </ul>
                    </div>
                    <div className="flex xxl:flex-row flex-col gap-8 lg:max-w-[calc(100%-340px)] max-w-full w-full">
                        <div className="content-section p-6 bg-white rounded-[10px] w-full h-full overflow-y-auto theme-scrollbar" ref={refs.left} >
                            <h2 className="lg:text-24 text-22 p-4 bg-black text-white rounded-lg px-8">Introduction</h2>
                            <div className="sub_content_widget inner_section" id="key-setup">
                                <h5 className="font-700 lg:text-20 text-18 mb-4">API Key Setup</h5>
                                <ul className="flex flex-col gap-2 list-disc ps-6">
                                    <li className="sm:text-16 text-14 font-500">Register
                                        with OppiWallet and from home page you can able for file the API
                                        Section.
                                    </li>
                                    <li className="sm:text-16 text-14 font-500 ">You
                                        can create many API for different purpose.
                                    </li>
                                    <li className="sm:text-16 text-14 font-500 ">You
                                        can get two keys which is Auth Key and Enc Key.
                                    </li>
                                    <li className="sm:text-16 text-14 font-500 ">Once
                                        it created from OppiWallet Application it will goes for admin approval
                                        once its approve you can integrate in your system.
                                    </li>
                                    <li className="sm:text-16 text-14 font-500 ">API
                                        Base Url : <span
                                            className="bg-black text-white p-1.5 px-2 rounded-lg">https://doc-api.oppiwallet.com/api</span>
                                    </li>
                                    <h4 className="lg:text-18 sm:text-16 text-14 mt-4 flex items-center gap-2 bg-[#b5b5b5] text-white p-[8px] px-6 rounded-lg">
                                        <IconAttention className="w-12 h-12 flex-shrink-0" />
                                        <p className="">If the API keys were accidentally shared, please delete them immediately and create a new key.</p>
                                    </h4>
                                </ul>
                            </div>
                            <div className="sub_content_widget inner_section" id="key-restrication">
                                <h5 className="font-700 lg:text-20 text-18 mb-4">API Key Restrictions</h5>
                                {/* <div className="overflow-x-auto"> */}
                                {/* <table className="border text-16 min-w-[320px]">
                        <thead className="border">
                          <tr>
                            <th className="border border-black p-4 w-[150px]">Security Type</th>
                            <th className="border border-black p-4 w-[150px]">Description</th>
                          </tr>
                        </thead>
                        <tbody className="text-14 font-500">
                          <tr>
                            <td className="border border-black p-4 w-[150px]">NONE</td>
                            <td className="border border-black p-4 w-[150px]">Endpoint can be accessed freely.</td>
                          </tr>
                          <tr>
                            <td className="border border-black p-4 w-[150px]">TRADE</td>
                            <td className="border border-black p-4 w-[150px]">Endpoint requires sending a valid API-Key and signature.</td>
                          </tr>
                        </tbody>
                      </table> */}
                                <ul className="flex flex-col gap-2 list-disc ps-6">
                                    <li className="sm:text-16 text-14 font-500">You
                                        can restrict your api key from a specific ip.
                                    </li>
                                    <li className="sm:text-16 text-14 font-500">Also
                                        you can enable or disable the key from the mobile app.
                                    </li>
                                </ul>
                                {/* </div> */}
                            </div>
                            <div className="sub_content_widget inner_section" id="auth-Key">
                                <h5 className="font-700 lg:text-20 text-18 mb-4">What is Auth Key?</h5>
                                <ul className="flex flex-col gap-2 list-disc ps-6">
                                    <li className="sm:text-16 text-14 font-500">All
                                        the apis you required to pass the auth key. This key you can consider as
                                        a public key.
                                    </li>
                                    <li className="sm:text-16 text-14 font-500">Auth
                                        key is required for identifying the OppiWallet register user and its
                                        API.
                                    </li>
                                </ul>
                            </div>
                            <div className="sub_content_widget inner_section" id="enc-Key">
                                <h5 className="font-700 lg:text-20 text-18 mb-4">What is Enc Key?</h5>
                                <ul className="flex flex-col gap-2 list-disc ps-6">
                                    <li className="sm:text-16 text-14 font-500">All
                                        the apis you require to make JWT and sign JWT using the Enc Key. This
                                        key you can consider as a private key.
                                    </li>
                                    <li className="sm:text-16 text-14 font-500">Enc
                                        key is required for identifying the OppiWallet register user and its
                                        API.
                                    </li>
                                    <li className="sm:text-16 text-14 font-500">If
                                        the API keys were accidentally shared, please delete them immediately
                                        and create a new key.
                                    </li>
                                </ul>
                            </div>
                            <div className="sub_content_widget inner_section" id="jwt-token">
                                <h5 className="font-700 lg:text-20 text-18 mb-4">Generate JWT Token</h5>
                                <ul className="flex flex-col gap-2 list-disc ps-6">
                                    <li className="sm:text-16 text-14 font-500">Algorithm
                                        will be : HS256
                                    </li>
                                    <li className="sm:text-16 text-14 font-500">You
                                        are required to make a signature using crypto sha256.
                                    </li>
                                    <h4 className="sm:text-16 text-14 font-500 mb-4 break-all">E.g.
                                        crypto.createHash("sha256").update(JSON.stringify(‘currency#amount#orderId’)).digest().toString("hex")</h4>
                                    <li className="sm:text-16 text-14 font-500">Following
                                        is your payload for json
                                    </li>
                                    <div>
                                        <h4 className="sm:text-16 text-14 font-500">&#123;</h4>
                                        <div className="code ps-12">
                                            <p className="sm:text-16 text-14 font-500">"url": "path",</p>
                                            <p className="sm:text-16 text-14 font-500">"iat":
                                                DateTime.now() (milisecond),</p>
                                            <p className="sm:text-16 text-14 font-500">"exp": DateTime.now() +
                                                60 (milisecond)</p>
                                            <p className="sm:text-16 text-14 font-500">"key": {"authKey"},</p>
                                            <p className="sm:text-16 text-14 font-500">"signature":
                                                signature</p>
                                        </div>
                                        <h4 className="sm:text-16 text-14 font-500">&#125;</h4>
                                    </div>
                                    <li className="sm:text-16 text-14 font-500">Datetime
                                        should be in GTM format.
                                    </li>
                                    <li className="sm:text-16 text-14 font-500">For
                                        the sign JWT we required to use Enc Key
                                    </li>
                                </ul>
                            </div>
                            <div className="sub_content_widget inner_section" id="enablibg-account">
                                <h5 className="font-700 lg:text-20 text-18 mb-4">Enabling Accounts</h5>
                                <h6 className="sm:text-18 text-16 font-600 mb-4">Main wallet</h6>
                                <h4 className="sm:text-16 text-14 font-500 mb-4">A <span
                                    className="bg-black text-white p-1.5 px-2 rounded-lg">Main</span> wallet is
                                    your default wallet, you will be able to deposit, withdraw and make trade.
                                </h4>
                            </div>
                            <div className="sub_content_widget inner_section" id="contact-us">
                                <h5 className="font-700 lg:text-20 text-18 mb-4">Contact Us</h5>
                                <p class="sm:text-18 text-16 font-600 mb-4">Reach out us on
                                    api@oppiwallet.com</p>
                                <ul className="flex flex-col gap-2 mb-4 list-disc ps-6">
                                    <li className="sm:text-16 text-14 font-500">For
                                        any questions in sudden drop in performance with the API and/or
                                        Websockets
                                    </li>
                                    <li className="sm:text-16 text-14 font-500">For
                                        any general questions about the API not covered in the documentation.
                                    </li>
                                </ul>
                                <p class="sm:text-18 text-16 font-600 mb-4">Oppi Wallet Developers</p>
                                <ul className="flex flex-col gap-2 mb-4 list-disc ps-6">
                                    <li className="sm:text-16 text-14 font-500">For
                                        any questions on your code implementation with the API and/or Websockets
                                        reach us out on api@oppiwallet.com
                                    </li>
                                </ul>
                                <p class="sm:text-18 text-16 font-600 mb-4">Oppi Wallet Customer Support</p>
                                <ul className="flex flex-col gap-2 list-disc ps-6">
                                    <li className="sm:text-16 text-14 font-500">For
                                        cases such as missing funds, help with 2FA, etc. reach us out on
                                        support@oppiwallet.com
                                    </li>
                                </ul>
                            </div>
                            <h2 className="lg:text-24 text-22 p-4 bg-black text-white rounded-lg px-8" id="api">API</h2>
                            <div class="sub_content_widget lg:px-20 sm:px-12 px-4" id='supported-currency' /* ref={el => (refs.left.current['1'] = el)} */>
                                <div className="border-b border-gray sm:py-10 py-6">
                                    <h5 className="font-700 lg:text-20 text-18 mb-4">Supported Currencies</h5>
                                    <div
                                        className="bg-[#b5b5b5] p-2 px-4 rounded-lg mb-4 flex items-center justify-between gap-[10px] relative">
                                        <h3 className="text-14 text-white flex gap-1.5 max-w-[480px] overflow-x-auto ">
                                            <span className="bg-black text-white p-1.5 px-2 rounded-lg">GET</span>
                                            <span
                                                className="bg-black text-white p-1.5 px-2 rounded-lg">/v1/supportedCurrency</span>
                                        </h3>
                                        <div
                                            className="bg-black h-[32px] w-[32px] rounded-full text-white flex items-center justify-center cursor-pointer flex-shrink-0"
                                            onClick={() => handleCopy('supported-currency', '/supportedCurrency')}>
                                            <IconCopy className="h-[20px] w-[20px]" />
                                        </div>
                                        {(id === 'supported-currency' && copy) &&
                                            <p className="absolute -top-[28px] end-0 bg-[#b5b5b5] rounded-[6px] text-[12px] p-[2px_4px] font-600">Copied</p>}
                                    </div>
                                    <h5 className="sm:text-16 text-14 mb-4">This api is used for get list of
                                        transactions.</h5>
                                    <div className="py-2">
                                        <h5 className="font-700 lg:text-20 text-18 mb-4">Headers:</h5>
                                        <div className="overflow-x-auto">
                                            <table className="border text-16 min-w-[660px]">
                                                <thead className="border">
                                                    <tr>
                                                        <th className="border border-black p-4 w-[150px]">Key</th>
                                                        <th className="border border-black p-4 w-[500px]">Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-14 font-600">
                                                    {/* <tr>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">authKey</td>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[500px]">Your Auth Key</td>
                            </tr>
                            <tr>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">encKey</td>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[500px]">Your Encryption Key
                              </td>
                            </tr> */}
                                                    <tr>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">signatureToken</td>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[500px]">
                                                            Generate JWT token as explain in section "Generate JWT
                                                            Token" using empty string
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <div className="py-2">
                      <h5 className="font-700 lg:text-20 text-18 mb-4">Request Body:</h5>
                      <div className="overflow-x-auto">
                        <table className="border text-16 min-w-[670px]">
                          <thead className="border">
                            <tr>
                              <th className="border border-black p-4 w-[150px]">Key</th>
                              <th className="border border-black p-4 w-[150px]">Value</th>
                              <th className="border border-black p-4">Description</th>
                            </tr>
                          </thead>
                          <tbody className="text-14 font-500">
                            <tr>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">currency</td>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">BTC</td>
                              <td className="border border-black text-dark-gray font-700 p-4">Assets Currency Code which you need fot deposit</td>
                            </tr>
                            <tr>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">amount</td>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">0.0001</td>
                              <td className="border border-black text-dark-gray font-700 p-4">Provide amount for transaction</td>
                            </tr>
                            <tr>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">orderId</td>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">1236454</td>
                              <td className="border border-black text-dark-gray font-700 p-4">Pass orderId for your reference</td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div> */}
                                </div>
                            </div>
                            <div class="sub_content_widget lg:px-20 sm:px-12 px-4" id='deposit' /* ref={el => (refs.left.current['2'] = el)} */>
                                <div className="border-b border-gray sm:py-10 py-6">
                                    <h5 className="font-700 lg:text-20 text-18 mb-4">Deposit</h5>
                                    <div
                                        className="bg-[#b5b5b5] p-2 px-4 rounded-lg mb-4 flex items-center justify-between gap-[10px] relative">
                                        <h3 className="text-14 text-white flex gap-1.5 max-w-[480px] overflow-x-auto ">
                                            <span className="bg-black text-white p-1.5 px-2 rounded-lg">POST</span>
                                            <span
                                                className="bg-black text-white p-1.5 px-2 rounded-lg">{apiLink}</span>
                                        </h3>
                                        <div
                                            className="bg-black h-[32px] w-[32px] rounded-full text-white flex items-center justify-center cursor-pointer flex-shrink-0"
                                            onClick={() => handleCopy('deposit', apiLink)}>
                                            <IconCopy className="h-[20px] w-[20px]" />
                                        </div>
                                        {(id === 'deposit' && copy) &&
                                            <p className="absolute -top-[28px] end-0 bg-[#b5b5b5] rounded-[6px] text-[12px] p-[2px_4px] font-600">Copied</p>}
                                    </div>
                                    <h5 className="sm:text-16 text-14 mb-4">This api is used for make new deposit
                                        address for receive payment.</h5>
                                    <div className="py-2">
                                        <h5 className="font-700 lg:text-20 text-18 mb-4">Headers:</h5>
                                        <div className="overflow-x-auto">
                                            <table className="border text-16 min-w-[660px]">
                                                <thead className="border">
                                                    <tr>
                                                        <th className="border border-black p-4 w-[150px]">Key</th>
                                                        <th className="border border-black p-4 w-[500px]">Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-14 font-600">
                                                    {/* <tr>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">authKey</td>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[500px]">Your Auth Key</td>
                            </tr>
                            <tr>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">encKey</td>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[500px]">Your Encryption Key
                              </td>
                            </tr> */}
                                                    <tr>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">signatureToken</td>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[500px]">
                                                            1.Make a string using same sequence and format which explain
                                                            below, currency#amount#orderId.<br />
                                                            2.Generate JWT token as explain in section "Generate JWT
                                                            Token" using above key
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="py-2">
                                        <h5 className="font-700 lg:text-20 text-18 mb-4">Request Body:</h5>
                                        <div className="overflow-x-auto">
                                            <table className="border text-16 min-w-[670px]">
                                                <thead className="border">
                                                    <tr>
                                                        <th className="border border-black p-4 w-[150px]">Key</th>
                                                        <th className="border border-black p-4 w-[150px]">Value</th>
                                                        <th className="border border-black p-4">Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-14 font-500">
                                                    <tr>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">currency</td>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">6698aec7f188ae24c673da1c</td>
                                                        <td className="border border-black text-dark-gray font-700 p-4">Assets
                                                            Currency id
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">amount</td>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">0.0001</td>
                                                        <td className="border border-black text-dark-gray font-700 p-4">Provide
                                                            amount for transaction
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">orderId</td>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">1236454</td>
                                                        <td className="border border-black text-dark-gray font-700 p-4">Pass
                                                            orderId for your reference
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sub_content_widget lg:px-20 sm:px-12 px-4" id='withdraw' /* ref={el => (refs.left.current[3] = el)} */>
                                <div className="border-b border-gray sm:py-10 py-6">
                                    <h5 className="font-700 lg:text-20 text-18 mb-4">Withdraw (Manual)</h5>
                                    <div
                                        className="bg-[#b5b5b5] p-2 px-4 rounded-lg mb-4 flex items-center justify-between gap-[10px] relative">
                                        <h3 className="text-14 text-white flex gap-1.5 max-w-[480px] overflow-x-auto ">
                                            <span className="bg-black text-white p-1.5 px-2 rounded-lg">POST</span>
                                            <span
                                                className="bg-black text-white p-1.5 px-2 rounded-lg">/v1/manualWithdraw</span>
                                        </h3>
                                        <div
                                            className="bg-black h-[32px] w-[32px] rounded-full text-white flex items-center justify-center cursor-pointer flex-shrink-0"
                                            onClick={() => handleCopy('withdraw', '/v1/manualWithdraw')}>
                                            <IconCopy className="h-[20px] w-[20px]" />
                                        </div>
                                        {(id === 'withdraw' && copy) &&
                                            <p className="absolute -top-[28px] end-0 bg-[#b5b5b5] rounded-[6px] text-[12px] p-[2px_4px] font-600">Copied</p>}
                                    </div>
                                    <h5 className="sm:text-16 text-14 mb-4">This api is used for make manual withdraw to
                                        external address.</h5>
                                    <div className="py-2">
                                        <h5 className="font-700 lg:text-20 text-18 mb-4">Headers:</h5>
                                        <div className="overflow-x-auto">
                                            <table className="border text-16 min-w-[660px]">
                                                <thead className="border">
                                                    <tr>
                                                        <th className="border border-black p-4 w-[150px]">Key</th>
                                                        <th className="border border-black p-4 w-[500px]">Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-14 font-600">
                                                    {/* <tr>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">authKey</td>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[500px]">Your Auth Key</td>
                            </tr>
                            <tr>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">encKey</td>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[500px]">Your Encryption Key
                              </td>
                            </tr> */}
                                                    <tr>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">signatureToken</td>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[500px]">
                                                            1.Make a string using same sequence and format which explain
                                                            below, currency#amount#address.<br />
                                                            2.Generate jwt token as explain in section "Generate JWT
                                                            Token" using above key
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {<div className="py-2">
                                        <h5 className="font-700 lg:text-20 text-18 mb-4">Request Body:</h5>
                                        <div className="overflow-x-auto">
                                            <table className="border text-16 min-w-[670px]">
                                                <thead className="border">
                                                    <tr>
                                                        <th className="border border-black p-4 w-[150px]">Key</th>
                                                        <th className="border border-black p-4 w-[150px]">Value</th>
                                                        <th className="border border-black p-4">Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-14 font-500">
                                                    <tr>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">currency</td>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">6698aec7f188ae24c673da1c</td>
                                                        <td className="border border-black text-dark-gray font-700 p-4">Assets
                                                            Currency id
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">amount</td>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">0.01</td>
                                                        <td className="border border-black text-dark-gray font-700 p-4">Provide
                                                            amount for transaction
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">address</td>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">0xAB710B08A1CDBD........1Ff9Ef231Ee212</td>
                                                        <td className="border border-black text-dark-gray font-700 p-4">Pass
                                                            address for your reference
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            <div class="sub_content_widget lg:px-20 sm:px-12 px-4" id='autoWithdraw' /* ref={el => (refs.left.current[3] = el)} */>
                                <div className="border-b border-gray sm:py-10 py-6">
                                    <h5 className="font-700 lg:text-20 text-18 mb-4">Withdraw (Auto)</h5>
                                    <div
                                        className="bg-[#b5b5b5] p-2 px-4 rounded-lg mb-4 flex items-center justify-between gap-[10px] relative">
                                        <h3 className="text-14 text-white flex gap-1.5 max-w-[480px] overflow-x-auto ">
                                            <span className="bg-black text-white p-1.5 px-2 rounded-lg">POST</span>
                                            <span
                                                className="bg-black text-white p-1.5 px-2 rounded-lg">/v1/autoWithdraw</span>
                                        </h3>
                                        <div
                                            className="bg-black h-[32px] w-[32px] rounded-full text-white flex items-center justify-center cursor-pointer flex-shrink-0"
                                            onClick={() => handleCopy('withdraw', '/v1/autoWithdraw')}>
                                            <IconCopy className="h-[20px] w-[20px]" />
                                        </div>
                                        {(id === 'withdraw' && copy) &&
                                            <p className="absolute -top-[28px] end-0 bg-[#b5b5b5] rounded-[6px] text-[12px] p-[2px_4px] font-600">Copied</p>}
                                    </div>
                                    <h5 className="sm:text-16 text-14 mb-4">This api is used for make automatic withdraw to external address. <strong>Make sure you follow below instructions otherwise it will not work.</strong></h5>
                                    <div className="py-2">
                                        <h5 className="font-700 lg:text-20 text-18 mb-4">Instructions:</h5>

                                        <p class="sm:text-18 text-16 font-600 mb-4">Source Code</p>
                                        <ul className="flex flex-col gap-2 mb-4 list-disc ps-6">
                                            <li className="sm:text-16 text-14 font-500">Download script from <a href={DownloadZip} download className="text-greenweb underline font-700">here</a>
                                            </li>
                                        </ul>

                                        <p class="sm:text-18 text-16 font-600 mb-4">Required Stuff</p>
                                        <ul className="flex flex-col gap-2 mb-4 list-disc ps-6">
                                            <li className="sm:text-16 text-14 font-500">Linux Server</li>
                                            <li className="sm:text-16 text-14 font-500">Node JS (v18.19.1 or later)</li>
                                        </ul>

                                        <p class="sm:text-18 text-16 font-600 mb-4">Setup</p>
                                        <ul className="flex flex-col gap-2 mb-4 list-disc ps-6">
                                            <li className="sm:text-16 text-14 font-500">Open config.json file and replace <span className="text-dark font-800">mnemonic</span>, <span className="text-dark font-800">apiKey</span>, <span className="text-dark font-800">encKey</span>.</li>
                                            <li className="sm:text-16 text-14 font-500">Execute pair.js file using : <span className="text-dark font-800">node pair.js</span>. It will install required packages.</li>
                                            <li className="sm:text-16 text-14 font-500">Next you need to start <span className="text-dark font-800">crypto.ts</span> on your server.</li>
                                            {/* <li className="sm:text-16 text-14 font-500">Note : web3.ts will use port <span className="text-dark font-800">7774</span>. Make this port executable from oppiwallet.</li> */}
                                        </ul>

                                    </div>

                                    <div className="py-2">
                                        <h5 className="font-700 lg:text-20 text-18 mb-4">Headers:</h5>
                                        <div className="overflow-x-auto">
                                            <table className="border text-16 min-w-[660px]">
                                                <thead className="border">
                                                    <tr>
                                                        <th className="border border-black p-4 w-[150px]">Key</th>
                                                        <th className="border border-black p-4 w-[500px]">Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-14 font-600">
                                                    {/* <tr>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">authKey</td>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[500px]">Your Auth Key</td>
                            </tr>
                            <tr>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">encKey</td>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[500px]">Your Encryption Key
                              </td>
                            </tr> */}
                                                    <tr>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">signatureToken</td>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[500px]">
                                                            1.Make a string using same sequence and format which explain
                                                            below, currency#amount#address.<br />
                                                            2.Generate jwt token as explain in section "Generate JWT
                                                            Token" using above key
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    {<div className="py-2">
                                        <h5 className="font-700 lg:text-20 text-18 mb-4">Request Body:</h5>
                                        <div className="overflow-x-auto">
                                            <table className="border text-16 min-w-[670px]">
                                                <thead className="border">
                                                    <tr>
                                                        <th className="border border-black p-4 w-[150px]">Key</th>
                                                        <th className="border border-black p-4 w-[150px]">Value</th>
                                                        <th className="border border-black p-4">Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-14 font-500">
                                                    <tr>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">currency</td>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">6698aec7f188ae24c673da1c</td>
                                                        <td className="border border-black text-dark-gray font-700 p-4">Assets
                                                            Currency id
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">amount</td>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">0.01</td>
                                                        <td className="border border-black text-dark-gray font-700 p-4">Provide
                                                            amount for transaction
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">address</td>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">0xAB710B08A1CDBD........1Ff9Ef231Ee212</td>
                                                        <td className="border border-black text-dark-gray font-700 p-4">Pass
                                                            address for your reference
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            <div class="sub_content_widget lg:px-20 sm:px-12 px-4" id='all-transactions' /* ref={el => (refs.left.current[4] = el)} */>
                                <div className="border-b border-gray sm:py-10 py-6">
                                    <h5 className="font-700 lg:text-20 text-18 mb-4">All Transactions </h5>
                                    <div
                                        className="bg-[#b5b5b5] p-2 px-4 rounded-lg mb-4 flex items-center justify-between gap-[10px] relative">
                                        <h3 className="text-14 text-white flex gap-1.5 max-w-[480px] overflow-x-auto ">
                                            <span className="bg-black text-white p-1.5 px-2 rounded-lg">GET</span>
                                            <span
                                                className="bg-black text-white p-1.5 px-2 rounded-lg">/v1/transactions</span>
                                        </h3>
                                        <div
                                            className="bg-black h-[32px] w-[32px] rounded-full text-white flex items-center justify-center cursor-pointer flex-shrink-0"
                                            onClick={() => handleCopy('all-transactions', '/v1/transactions')}>
                                            <IconCopy className="h-[20px] w-[20px]" />
                                        </div>
                                        {(id === 'all-transactions' && copy) &&
                                            <p className="absolute -top-[28px] end-0 bg-[#b5b5b5] rounded-[6px] text-[12px] p-[2px_4px] font-600">Copied</p>}
                                    </div>
                                    <h5 className="sm:text-16 text-14 mb-4">This api is used for get all
                                        transactions with status.<br />Status : 0=Pending, 1=Completed, 2=Failed</h5>
                                    <div className="py-2">
                                        <h5 className="font-700 lg:text-20 text-18 mb-4">Headers:</h5>
                                        <div className="overflow-x-auto">
                                            <table className="border text-16 min-w-[660px]">
                                                <thead className="border">
                                                    <tr>
                                                        <th className="border border-black p-4 w-[150px]">Key</th>
                                                        <th className="border border-black p-4 w-[500px]">Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-14 font-600">
                                                    {/* <tr>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">authKey</td>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[500px]">Your Auth Key</td>
                            </tr>
                            <tr>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">encKey</td>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[500px]">Your Encryption Key
                              </td>
                            </tr> */}
                                                    <tr>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">signatureToken</td>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[500px]">
                                                            Generate JWT token as explain in section "Generate JWT
                                                            Token" using empty string
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sub_content_widget lg:px-20 sm:px-12 px-4" id='single-transaction' /* ref={el => (refs.left.current[5] = el)} */>
                                <div className="border-b border-gray sm:py-10 py-6">
                                    <h5 className="font-700 lg:text-20 text-18 mb-4">Transaction</h5>
                                    <div
                                        className="bg-[#b5b5b5] p-2 px-4 rounded-lg mb-4 flex items-center justify-between gap-[10px] relative">
                                        <h3 className="text-14 text-white flex gap-1.5 max-w-[480px] overflow-x-auto ">
                                            <span className="bg-black text-white p-1.5 px-2 rounded-lg">GET</span>
                                            <span
                                                className="bg-black text-white p-1.5 px-2 rounded-lg">/v1/transaction/&#123;transactionId&#125;</span>
                                        </h3>
                                        <div
                                            className="bg-black h-[32px] w-[32px] rounded-full text-white flex items-center justify-center cursor-pointer flex-shrink-0"
                                            onClick={() => handleCopy('single-transaction', '/v1/transaction/transactionId')}>
                                            <IconCopy className="h-[20px] w-[20px]" />
                                        </div>
                                        {(id === 'single-transaction' && copy) &&
                                            <p className="absolute -top-[28px] end-0 bg-[#b5b5b5] rounded-[6px] text-[12px] p-[2px_4px] font-600">Copied</p>}
                                    </div>
                                    <h5 className="sm:text-16 text-14 mb-4">This api is used for get single
                                        transaction based on transactionId with status.<br />Status
                                        : 0=Pending, 1=Completed, 2=Failed</h5>
                                    <div className="py-2">
                                        <h5 className="font-700 lg:text-20 text-18 mb-4">Headers:</h5>
                                        <div className="overflow-x-auto">
                                            <table className="border text-16 min-w-[660px]">
                                                <thead className="border">
                                                    <tr>
                                                        <th className="border border-black p-4 w-[150px]">Key</th>
                                                        <th className="border border-black p-4 w-[500px]">Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-14 font-600">
                                                    {/* <tr>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">authKey</td>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[500px]">Your Auth Key</td>
                            </tr>
                            <tr>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">encKey</td>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[500px]">Your Encryption Key
                              </td>
                            </tr> */}
                                                    <tr>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">signatureToken</td>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[500px]">
                                                            Generate JWT token as explain in section "Generate JWT
                                                            Token" using empty string
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sub_content_widget lg:px-20 sm:px-12 px-4" id='single-balance' /* ref={el => (refs.left.current[5] = el)} */>
                                <div className="border-b border-gray sm:py-10 py-6">
                                    <h5 className="font-700 lg:text-20 text-18 mb-4">Get Balance</h5>
                                    <div
                                        className="bg-[#b5b5b5] p-2 px-4 rounded-lg mb-4 flex items-center justify-between gap-[10px] relative">
                                        <h3 className="text-14 text-white flex gap-1.5 max-w-[480px] overflow-x-auto ">
                                            <span className="bg-black text-white p-1.5 px-2 rounded-lg">GET</span>
                                            <span
                                                className="bg-black text-white p-1.5 px-2 rounded-lg">/v1/balance/&#123;currencyId&#125;</span>
                                        </h3>
                                        <div
                                            className="bg-black h-[32px] w-[32px] rounded-full text-white flex items-center justify-center cursor-pointer flex-shrink-0"
                                            onClick={() => handleCopy('single-balance', '/v1/balance/&#123;currencyId&#125;')}>
                                            <IconCopy className="h-[20px] w-[20px]" />
                                        </div>
                                        {(id === 'single-balance' && copy) &&
                                            <p className="absolute -top-[28px] end-0 bg-[#b5b5b5] rounded-[6px] text-[12px] p-[2px_4px] font-600">Copied</p>}
                                    </div>
                                    <h5 className="sm:text-16 text-14 mb-4">This api is used for get user wallet balance.<br /></h5>
                                    <div className="py-2">
                                        <h5 className="font-700 lg:text-20 text-18 mb-4">Headers:</h5>
                                        <div className="overflow-x-auto">
                                            <table className="border text-16 min-w-[660px]">
                                                <thead className="border">
                                                    <tr>
                                                        <th className="border border-black p-4 w-[150px]">Key</th>
                                                        <th className="border border-black p-4 w-[500px]">Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-14 font-600">
                                                    {/* <tr>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">authKey</td>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[500px]">Your Auth Key</td>
                            </tr>
                            <tr>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">encKey</td>
                              <td className="border border-black text-dark-gray font-700 p-4 w-[500px]">Your Encryption Key
                              </td>
                            </tr> */}
                                                    <tr>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[150px]">signatureToken</td>
                                                        <td className="border border-black text-dark-gray font-700 p-4 w-[500px]">
                                                            Generate JWT token as explain in section "Generate JWT
                                                            Token" using empty string
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right-side bg-white rounded-[10px] w-full xxl:max-w-[460px] max-w-full p-10 xxl:overflow-y-auto theme-scrollbar" id="api" ref={el => (refs.right.current['1'] = el)}>
                            <div className="pb-[20px] border-b border-gray" id="right-supported-currency" /* ref={el => (refs.right.current['1'] = el)} */>
                                <h2 className="text-[16px] py-2 bg-black text-white rounded-lg px-4 mb-2"
                                >Supported Currencies</h2>
                                <p className="text-card text-[14px] font-600 uppercase">Language</p>
                                <div className="mt-[10px] mb-[20px] ">
                                    <div className="flex items-center gap-[4px] flex-wrap mb-[20px]">
                                        {
                                            language.map((data, index) =>
                                                <div
                                                    className={`flex items-center justify-center flex-col gap-[4px] rounded-[8px] border ${data.id === lang.id ? 'border-greenweb' : 'border-transparent'} hover:border-greenweb p-[6px_10px] min-w-[80px] cursor-pointer`}
                                                    onClick={() => setLang(data)}>
                                                    {data.icon}
                                                    <p className="text-[10px]">{data.title}</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                    {
                                        lang.title === 'Shell' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="api_code">
                                                    <p className="text-[12px]">curl --location
                                                        'https://doc-api.oppiwallet.com/api/supportedCurrency'</p>
                                                    <p className="text-[12px]">--header 'signatureToken:
                                                        eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw'</p>
                                                    <p className="text-[12px]">--header 'Content-Type:
                                                        application/json'</p>
                                                    <br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('api', 'api_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'api' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                    {
                                        lang.title === 'Python' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="api_code">
                                                    <p className="text-[12px]">import http.client'</p>
                                                    <p className="text-[12px]">import json</p><br />
                                                    <p className="text-[12px]">conn =
                                                        http.client.HTTPSConnection("doc-api.oppiwallet.com")</p>
                                                    <div>
                                                        <h4 className="text-[12px]">headers = &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">'signatureToken:
                                                                eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNDlkNTdhMGIyNmRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw'</p>
                                                            <p className="text-[12px]">'Content-Type:
                                                                application/json'</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;</h4>
                                                    </div>
                                                    <p className="text-[12px]">conn.request("GET",
                                                        "/api/supportedCurrency", headers)</p>
                                                    <p className="text-[12px]">res = conn.getresponse()</p>
                                                    <p className="text-[12px]">data = res.read()</p>
                                                    <p className="text-[12px]">print(data.decode("utf-8"))</p><br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('api', 'api_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'api' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                    {
                                        lang.title === 'PHP' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="api_code">
                                                    <p className="text-[12px]">$client = new Client();</p>
                                                    <div>
                                                        <h4 className="text-[12px]">headers = [</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">'signatureToken:
                                                                eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNDlkNTdhMGIyNmRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw'</p>
                                                            <p className="text-[12px]">'Content-Type:
                                                                application/json'</p>
                                                        </div>
                                                        <h4 className="text-[12px]">];</h4>
                                                    </div>
                                                    <p className="text-[12px]">$request = new Request('GET',
                                                        'https://doc-api.oppiwallet.com/api/supportedCurrency',
                                                        $headers);</p>
                                                    <p className="text-[12px]">$res =
                                                        $client->sendAsync($request)->wait();</p>
                                                    <p className="text-[12px]">echo $res->getBody();</p><br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('api', 'api_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'api' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                    {
                                        lang.title === 'NodeJS' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="api_code">
                                                    <p className="text-[12px]">const axios = require('axios');</p>
                                                    <br />
                                                    <div>
                                                        <h4 className="text-[12px]">let config = &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">method: 'get',</p>
                                                            <p className="text-[12px]">maxBodyLength: Infinity,</p>
                                                            <p className="text-[12px]">url:
                                                                'https://doc-api.oppiwallet.com/api/supportedCurrency',</p>
                                                            <div>
                                                                <h4 className="text-[12px]">headers: '&#123;</h4>
                                                                <div className="code ps-12">
                                                                    <p className="text-[12px]">'signatureToken':
                                                                        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw', </p>
                                                                    <p className="text-[12px]">'Content-Type':
                                                                        'application/json'</p>
                                                                </div>
                                                                <h4 className="text-[12px]">&#125;'</h4>
                                                            </div>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;;</h4>
                                                    </div>
                                                    <br />
                                                    <p className="text-[12px]">axios.request(config)</p>
                                                    <div>
                                                        <h4 className="text-[12px]">.then((response) => &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">console.log(JSON.stringify(response.data));</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;)</h4>
                                                    </div>
                                                    <div>
                                                        <h4 className="text-[12px]">.catch((error) => &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">console.log(error);</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;);</h4>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('api', 'api_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'api' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="relative">
                                    <div className="border border-light-gray rounded-[8px]">
                                        <div className="flex items-center justify-between gap-[6px] p-[8px]">
                                            <h5 className="text-[16px]">Response</h5>
                                            <div className="relative">
                                                <div
                                                    className="cursor-pointer outline-none text-[14px] flex items-center gap-3"
                                                    onClick={() => { setOpen(!open); setId('api') }}>
                                                    <span className="flex gap-1 items-center">
                                                        {selectValue.icon} {selectValue.status}
                                                    </span>
                                                    <IconDown className="w-6 h-6 text-dark-gray" />
                                                </div>
                                                {
                                                    (id === 'api' && open) &&
                                                    <div
                                                        className="bg-[#f1f1f1] border border-light-gray absolute bottom-0-0 w-full">
                                                        {
                                                            resList.map((data, index) =>
                                                                <span
                                                                    className="flex items-center gap-2 cursor-pointer text-[14px]"
                                                                    onClick={() => {
                                                                        setSelectValue(data);
                                                                        setOpen(false)
                                                                    }}>{data.icon} {data.status} </span>
                                                            )
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div
                                            className="px-[6px] ps-[10px] pb-[46px] xxl:max-w-[430px] max-w-full overflow-y-auto response-content" id='api_res'>
                                            {selectValue.status === '200' && <div>
                                                <h4 className="text-12">[</h4>
                                                <div className="code ps-12">
                                                    <h4 className="text-12">&#123;</h4>
                                                    <div className="code ps-12">
                                                        <h4 className="text-12">"name": "Bitcoin",</h4>
                                                        <h4 className="text-12">"symbol": "BTC",</h4>
                                                        <h4 className="text-12">"decimal": 18</h4>
                                                        <h4 className="text-12">"usdPrice": "0.4573",</h4>
                                                        <h4 className="text-12">"eurPrice": "0.421934928",</h4>
                                                        <h4 className="text-12">"tryPrice": "14.934817532999999",</h4>
                                                        <h4 className="text-12">"minPaymentAmount": "1",</h4>
                                                        <h4 className="text-12">"id": "6698aec7f188ae24c673da1c"</h4>
                                                    </div>
                                                    <h4 className="text-12">&#125;</h4>
                                                </div>
                                                <h4 className="text-12">]</h4>
                                            </div>}
                                            {selectValue.status === '400' && <div>
                                                <h4 className="text-12">&#123;</h4>
                                                <div className="code ps-12">
                                                    <h4 className="text-12"> "message": "Error message"</h4>
                                                </div>
                                                <h4 className="text-12">&#125;</h4>
                                            </div>}
                                            <br />
                                        </div>
                                    </div>
                                    <div
                                        className="bg-[#f1f1f1] p-[10px] border border-light-gray border-t-0  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                        <div className="cursor-pointer w-fit flex" onClick={() => responseCopy('api', 'api_res')}>
                                            <IconCopy className='h-[24px] w-[24px] text-dark-gray' />
                                        </div>
                                        {(id === 'api' && copyResponse) &&
                                            <p className="bg-gray text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="pb-[20px] pt-[20px] border-b border-gray" id="right-deposit" /* ref={el => (refs.right.current['2'] = el)} */>
                                <h2 className="text-[16px] py-2 bg-black text-white rounded-lg px-4 mb-2">Deposit</h2>
                                <p className="text-card text-[14px] font-600 uppercase">Language</p>
                                <div className="mt-[10px] mb-[20px] ">
                                    <div className="flex items-center gap-[4px] flex-wrap mb-[20px]">
                                        {
                                            language.map((data, index) =>
                                                <div
                                                    className={`flex items-center justify-center flex-col gap-[4px] rounded-[8px] border ${data.id === lang.id ? 'border-greenweb' : 'border-transparent'} hover:border-greenweb p-[6px_10px] min-w-[80px] cursor-pointer`}
                                                    onClick={() => setLang(data)}>
                                                    {data.icon}
                                                    <p className="text-[10px]">{data.title}</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                    {
                                        lang.title === 'Shell' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="deposit_code">
                                                    <p className="text-[12px]">curl --location
                                                        'https://doc-api.oppiwallet.com/api/v1/createTransactionRequest'</p>
                                                    <p className="text-[12px]">--header 'signatureToken:
                                                        eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw'</p>
                                                    <p className="text-[12px]">--header 'Content-Type:
                                                        application/json'</p>
                                                    <div>
                                                        <h4 className="text-[12px]">--data '&#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">"currency":"6698aec7f188ae24c673da1c",</p>
                                                            <p className="text-[12px]">"amount":"0.01",</p>
                                                            <p className="text-[12px]">"orderId":"ORDER_1"</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;'</h4>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('right-deposit', 'deposit_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'right-deposit' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                    {
                                        lang.title === 'Python' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="deposit_code">
                                                    <p className="text-[12px]">import http.client'</p>
                                                    <p className="text-[12px]">import json</p><br />
                                                    <p className="text-[12px]">conn =
                                                        http.client.HTTPSConnection("doc-api.oppiwallet.com")</p>
                                                    <div>
                                                        <h4 className="text-[12px]">payload = json.dumps(&#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">"currency":"6698aec7f188ae24c673da1c",</p>
                                                            <p className="text-[12px]">"amount":"0.01",</p>
                                                            <p className="text-[12px]">"orderId":"ORDER_1"</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;)</h4>
                                                    </div>
                                                    <div>
                                                        <h4 className="text-[12px]">headers = &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">'signatureToken:
                                                                eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNDlkNTdhMGIyNmRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw'</p>
                                                            <p className="text-[12px]">'Content-Type:
                                                                application/json'</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;</h4>
                                                    </div>
                                                    <p className="text-[12px]">conn.request("POST",
                                                        "/api/v1/createTransactionRequest", payload, headers)</p>
                                                    <p className="text-[12px]">res = conn.getresponse()</p>
                                                    <p className="text-[12px]">data = res.read()</p>
                                                    <p className="text-[12px]">print(data.decode("utf-8"))</p><br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('right-deposit', 'deposit_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'right-deposit' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                    {
                                        lang.title === 'PHP' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="deposit_code">
                                                    <p className="text-[12px]">$client = new Client();</p>
                                                    <div>
                                                        <h4 className="text-[12px]">headers = [</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">'signatureToken:
                                                                eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNDlkNTdhMGIyNmRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw'</p>
                                                            <p className="text-[12px]">'Content-Type:
                                                                application/json'</p>
                                                        </div>
                                                        <h4 className="text-[12px]">];</h4>
                                                    </div>
                                                    <div>
                                                        <h4 className="text-[12px]">$body = '&#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">"currency":"6698aec7f188ae24c673da1c",</p>
                                                            <p className="text-[12px]">"amount":"0.01",</p>
                                                            <p className="text-[12px]">"orderId":"ORDER_1"</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;';</h4>
                                                    </div>
                                                    <p className="text-[12px]">$request = new Request('POST',
                                                        'https://doc-api.oppiwallet.com/api/v1/createTransactionRequest', $headers,
                                                        $body);</p>
                                                    <p className="text-[12px]">$res =
                                                        $client->sendAsync($request)->wait();</p>
                                                    <p className="text-[12px]">echo $res->getBody();</p><br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('right-deposit', 'deposit_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'right-deposit' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                    {
                                        lang.title === 'NodeJS' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="deposit_code">
                                                    <p className="text-[12px]">const axios = require('axios');</p>
                                                    <div>
                                                        <h4 className="text-[12px]">let data =
                                                            JSON.stringify(&#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">"currency":"6698aec7f188ae24c673da1c",</p>
                                                            <p className="text-[12px]">"amount":"0.01",</p>
                                                            <p className="text-[12px]">"orderId":"ORDER_1"</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;);</h4>
                                                    </div>
                                                    <br />
                                                    <div>
                                                        <h4 className="text-[12px]">let config = &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">method: 'post',</p>
                                                            <p className="text-[12px]">maxBodyLength: Infinity,</p>
                                                            <p className="text-[12px]">url:
                                                                'https://doc-api.oppiwallet.com/api/v1/createTransactionRequest',</p>
                                                            <div>
                                                                <h4 className="text-[12px]">headers: '&#123;</h4>
                                                                <div className="code ps-12">
                                                                    <p className="text-[12px]">'signatureToken':
                                                                        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw', </p>
                                                                    <p className="text-[12px]">'Content-Type':
                                                                        'application/json'</p>
                                                                </div>
                                                                <h4 className="text-[12px]">&#125;',</h4>
                                                            </div>
                                                            <p className="text-[12px]">data : data</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;;</h4>
                                                    </div>
                                                    <br />
                                                    <p className="text-[12px]">axios.request(config)</p>
                                                    <div>
                                                        <h4 className="text-[12px]">.then((response) => &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">console.log(JSON.stringify(response.data));</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;)</h4>
                                                    </div>
                                                    <div>
                                                        <h4 className="text-[12px]">.catch((error) => &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">console.log(error);</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;);</h4>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('right-deposit', 'deposit_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'right-deposit' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="relative">
                                    <div className="border border-light-gray rounded-[8px]">
                                        <div className="flex items-center justify-between gap-[6px] p-[8px]">
                                            <h5 className="text-[16px]">Response</h5>
                                            <div className="relative">
                                                <div
                                                    className="cursor-pointer outline-none text-[14px] flex items-center gap-3"
                                                    onClick={() => { setOpen(true); setId('right-deposit') }}>
                                                    <span className="flex gap-1 items-center">
                                                        {selectValue.icon} {selectValue.status}
                                                    </span>
                                                    <IconDown className="w-6 h-6 text-dark-gray" />
                                                </div>
                                                {
                                                    (id === 'right-deposit' && open) &&
                                                    <div
                                                        className="bg-[#f1f1f1] border border-light-gray absolute bottom-0-0 w-full">
                                                        {
                                                            resList.map((data, index) =>
                                                                <span
                                                                    className="flex items-center gap-2 cursor-pointer text-[14px]"
                                                                    onClick={() => {
                                                                        setSelectValue(data);
                                                                        setOpen(false)
                                                                    }}>{data.icon} {data.status} </span>
                                                            )
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div
                                            className="px-[6px] ps-[10px] pb-[46px] xxl:max-w-[430px] max-w-full overflow-y-auto response-content" id='deposit_res'>
                                            {selectValue.status === '200' && <div>
                                                <h4 className="text-12">&#123;</h4>
                                                <div className="code ps-12">
                                                    <h4 className="text-12">"address":
                                                        "0x6648C29C6FD8........eD9Eb964c6F838bF",</h4>
                                                    <h4 className="text-12">"amount": "0.0001"</h4>
                                                    <h4 className="text-12">"tag": "2165464"</h4>
                                                    <h4 className="text-12">"transactionId":
                                                        "0866f8-4a53-4579-9274-351ec868a627"</h4>
                                                </div>
                                                <h4 className="text-12">&#125;</h4>
                                            </div>}
                                            {selectValue.status === '400' && <div>
                                                <h4 className="text-12">&#123;</h4>
                                                <div className="code ps-12">
                                                    <h4 className="text-12"> "message": "Error message"</h4>
                                                </div>
                                                <h4 className="text-12">&#125;</h4>
                                            </div>}
                                            <br />
                                        </div>
                                    </div>
                                    <div
                                        className="bg-[#f1f1f1] p-[10px] border border-light-gray border-t-0  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                        <div className="cursor-pointer w-fit flex" onClick={() => responseCopy('right-deposit', 'deposit_res')}>
                                            <IconCopy className='h-[24px] w-[24px] text-dark-gray' />
                                        </div>
                                        {(id === 'right-deposit' && copyResponse) &&
                                            <p className="bg-gray text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="pb-[20px] pt-[20px] border-b border-gray" id="right-withdraw" /* ref={el => (refs.right.current[3] = el)} */>
                                <h2 className="text-[16px] py-2 bg-black text-white rounded-lg px-4 mb-2"
                                    id="Auto-withdraw-collection">Withdraw (Manual)</h2>
                                <p className="text-card text-[14px] font-600 uppercase">Language</p>
                                <div className="mt-[10px] mb-[20px]">
                                    <div className="flex items-center gap-[4px] flex-wrap mb-[20px]">
                                        {
                                            language.map((data, index) =>
                                                <div
                                                    className={`flex items-center justify-center flex-col gap-[4px] rounded-[8px] border ${data.id === lang.id ? 'border-greenweb' : 'border-transparent'} hover:border-greenweb p-[6px_10px] min-w-[80px] cursor-pointer`}
                                                    onClick={() => setLang(data)}>
                                                    {data.icon}
                                                    <p className="text-[10px]">{data.title}</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                    {
                                        lang.title === 'Shell' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="withdraw_code">
                                                    <p className="text-[12px]">curl --location
                                                        'https://doc-api.oppiwallet.com/api/v1/manualWithdraw'</p>
                                                    <p className="text-[12px]">--header 'signatureToken:
                                                        eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw'</p>
                                                    <p className="text-[12px]">--header 'Content-Type:
                                                        application/json'</p>
                                                    <div>
                                                        <h4 className="text-[12px]">--data '&#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">"currency":"6698aec7f188ae24c673da1c",</p>
                                                            <p className="text-[12px]">"amount":"0.01",</p>
                                                            <p className="text-[12px]">"address":"0xb6b5E02EBf4d20......3AfAbA034f2F8D8D07"</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;'</h4>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('right-withdraw', 'withdraw_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'right-withdraw' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                    {
                                        lang.title === 'Python' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="withdraw_code">
                                                    <p className="text-[12px]">import http.client'</p>
                                                    <p className="text-[12px]">import json</p><br />
                                                    <p className="text-[12px]">conn =
                                                        http.client.HTTPSConnection("doc-api.oppiwallet.com")</p>
                                                    <div>
                                                        <h4 className="text-[12px]">payload = json.dumps(&#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">"currency":"6698aec7f188ae24c673da1c",</p>
                                                            <p className="text-[12px]">"amount":"0.01",</p>
                                                            <p className="text-[12px]">"address":"0xb6b5E02EBf4d20......3AfAbA034f2F8D8D07"</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;)</h4>
                                                    </div>
                                                    <div>
                                                        <h4 className="text-[12px]">headers = &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">'signatureToken:
                                                                eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNDlkNTdhMGIyNmRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw'</p>
                                                            <p className="text-[12px]">'Content-Type:
                                                                application/json'</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;</h4>
                                                    </div>
                                                    <p className="text-[12px]">conn.request("POST",
                                                        "/api/v1/manualWithdraw", payload, headers)</p>
                                                    <p className="text-[12px]">res = conn.getresponse()</p>
                                                    <p className="text-[12px]">data = res.read()</p>
                                                    <p className="text-[12px]">print(data.decode("utf-8"))</p><br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('right-withdraw', 'withdraw_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'right-withdraw' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                    {
                                        lang.title === 'PHP' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="withdraw_code">
                                                    <p className="text-[12px]">$client = new Client();</p>
                                                    <div>
                                                        <h4 className="text-[12px]">headers = [</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">'signatureToken:
                                                                eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNDlkNTdhMGIyNmRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw'</p>
                                                            <p className="text-[12px]">'Content-Type:
                                                                application/json'</p>
                                                        </div>
                                                        <h4 className="text-[12px]">];</h4>
                                                    </div>
                                                    <div>
                                                        <h4 className="text-[12px]">$body = '&#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">"currency":"6698aec7f188ae24c673da1c",</p>
                                                            <p className="text-[12px]">"amount":"0.01",</p>
                                                            <p className="text-[12px]">"address":"0xb6b5E02EBf4d20......3AfAbA034f2F8D8D07"</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;';</h4>
                                                    </div>
                                                    <p className="text-[12px]">$request = new Request('POST',
                                                        'https://doc-api.oppiwallet.com/api/v1/manualWithdraw',
                                                        $headers, $body);</p>
                                                    <p className="text-[12px]">$res =
                                                        $client->sendAsync($request)->wait();</p>
                                                    <p className="text-[12px]">echo $res->getBody();</p><br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('right-withdraw', 'withdraw_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'right-withdraw' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                    {
                                        lang.title === 'NodeJS' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="withdraw_code">
                                                    <p className="text-[12px]">const axios = require('axios');</p>
                                                    <div>
                                                        <h4 className="text-[12px]">let data =
                                                            JSON.stringify(&#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">"currency":"6698aec7f188ae24c673da1c",</p>
                                                            <p className="text-[12px]">"amount":"0.01",</p>
                                                            <p className="text-[12px]">"address":"0xb6b5E02EBf4d20......3AfAbA034f2F8D8D07"</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;);</h4>
                                                    </div>
                                                    <br />
                                                    <div>
                                                        <h4 className="text-[12px]">let config = &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">method: 'post',</p>
                                                            <p className="text-[12px]">maxBodyLength: Infinity,</p>
                                                            <p className="text-[12px]">url:
                                                                'https://doc-api.oppiwallet.com/api/v1/manualWithdraw',</p>
                                                            <div>
                                                                <h4 className="text-[12px]">headers: '&#123;</h4>
                                                                <div className="code ps-12">
                                                                    <p className="text-[12px]">'signatureToken':
                                                                        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw', </p>
                                                                    <p className="text-[12px]">'Content-Type':
                                                                        'application/json'</p>
                                                                </div>
                                                                <h4 className="text-[12px]">&#125;',</h4>
                                                            </div>
                                                            <p className="text-[12px]">data : data</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;;</h4>
                                                    </div>
                                                    <br />
                                                    <p className="text-[12px]">axios.request(config)</p>
                                                    <div>
                                                        <h4 className="text-[12px]">.then((response) => &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">console.log(JSON.stringify(response.data));</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;)</h4>
                                                    </div>
                                                    <div>
                                                        <h4 className="text-[12px]">.catch((error) => &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">console.log(error);</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;);</h4>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('right-withdraw', 'withdraw_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'right-withdraw' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="relative">
                                    <div className="border border-light-gray rounded-[8px]">
                                        <div className="flex items-center justify-between gap-[6px] p-[8px]">
                                            <h5 className="text-[16px]">Response</h5>
                                            <div className="relative">
                                                <div
                                                    className="cursor-pointer outline-none text-[14px] flex items-center gap-3"
                                                    onClick={() => { setOpen(true); setId('right-withdraw') }}>
                                                    <span className="flex gap-1 items-center">
                                                        {selectValue.icon} {selectValue.status}
                                                    </span>
                                                    <IconDown className="w-6 h-6 text-dark-gray" />
                                                </div>
                                                {
                                                    (id === 'right-withdraw' && open) &&
                                                    <div
                                                        className="bg-[#f1f1f1] border border-light-gray absolute bottom-0-0 w-full">
                                                        {
                                                            resList.map((data, index) =>
                                                                <span
                                                                    className="flex items-center gap-2 cursor-pointer text-[14px]"
                                                                    onClick={() => {
                                                                        setSelectValue(data);
                                                                        setOpen(false)
                                                                    }}>{data.icon} {data.status} </span>
                                                            )
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div
                                            className="px-[6px] ps-[10px] pb-[46px] xxl:max-w-[430px] max-w-full overflow-y-auto response-content" id='withdraw_res'>
                                            {selectValue.status === '200' && <div>
                                                <h4 className="text-12">&#123;</h4>
                                                <div className="code ps-12">
                                                    {/* <h4 className="text-12">"address":
                                                        "0xb6b5E02EBf4d20......3AfAbA0F8D8D07",</h4>
                                                    <h4 className="text-12">"amount": "0.01"</h4>
                                                    <h4 className="text-12">"tag": "2165464"</h4> */}
                                                    <h4 className="text-12">"message":
                                                        "Your withdraw request send successfully!"</h4>
                                                </div>
                                                <h4 className="text-12">&#125;</h4>
                                            </div>}
                                            {selectValue.status === '400' && <div>
                                                <h4 className="text-12">&#123;</h4>
                                                <div className="code ps-12">
                                                    <h4 className="text-12"> "message": "Error message"</h4>
                                                </div>
                                                <h4 className="text-12">&#125;</h4>
                                            </div>}
                                            <br />
                                        </div>
                                    </div>
                                    <div
                                        className="bg-[#f1f1f1] p-[10px] border border-light-gray border-t-0  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                        <div className="cursor-pointer w-fit flex" onClick={() => responseCopy('right-withdraw', 'withdraw_res')}>
                                            <IconCopy className='h-[24px] w-[24px] text-dark-gray' />
                                        </div>
                                        {(id === 'right-withdraw' && copyResponse) &&
                                            <p className="bg-gray text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="pb-[20px] pt-[20px] border-b border-gray" id="right-withdraw-auto" /* ref={el => (refs.right.current[3] = el)} */>
                                <h2 className="text-[16px] py-2 bg-black text-white rounded-lg px-4 mb-2"
                                    id="Auto-withdraw-collection">Withdraw (Auto)</h2>
                                <p className="text-card text-[14px] font-600 uppercase">Language</p>
                                <div className="mt-[10px] mb-[20px]">
                                    <div className="flex items-center gap-[4px] flex-wrap mb-[20px]">
                                        {
                                            language.map((data, index) =>
                                                <div
                                                    className={`flex items-center justify-center flex-col gap-[4px] rounded-[8px] border ${data.id === lang.id ? 'border-greenweb' : 'border-transparent'} hover:border-greenweb p-[6px_10px] min-w-[80px] cursor-pointer`}
                                                    onClick={() => setLang(data)}>
                                                    {data.icon}
                                                    <p className="text-[10px]">{data.title}</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                    {
                                        lang.title === 'Shell' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="withdraw_code">
                                                    <p className="text-[12px]">curl --location
                                                        'https://doc-api.oppiwallet.com/api/v1/autoWithdraw'</p>
                                                    <p className="text-[12px]">--header 'signatureToken:
                                                        eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw'</p>
                                                    <p className="text-[12px]">--header 'Content-Type:
                                                        application/json'</p>
                                                    <div>
                                                        <h4 className="text-[12px]">--data '&#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">"currency":"6698aec7f188ae24c673da1c",</p>
                                                            <p className="text-[12px]">"amount":"0.01",</p>
                                                            <p className="text-[12px]">"address":"0xb6b5E02EBf4d20......3AfAbA034f2F8D8D07"</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;'</h4>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('right-withdraw', 'withdraw_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'right-withdraw' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                    {
                                        lang.title === 'Python' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="withdraw_code">
                                                    <p className="text-[12px]">import http.client'</p>
                                                    <p className="text-[12px]">import json</p><br />
                                                    <p className="text-[12px]">conn =
                                                        http.client.HTTPSConnection("doc-api.oppiwallet.com")</p>
                                                    <div>
                                                        <h4 className="text-[12px]">payload = json.dumps(&#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">"currency":"6698aec7f188ae24c673da1c",</p>
                                                            <p className="text-[12px]">"amount":"0.01",</p>
                                                            <p className="text-[12px]">"address":"0xb6b5E02EBf4d20......3AfAbA034f2F8D8D07"</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;)</h4>
                                                    </div>
                                                    <div>
                                                        <h4 className="text-[12px]">headers = &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">'signatureToken:
                                                                eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNDlkNTdhMGIyNmRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw'</p>
                                                            <p className="text-[12px]">'Content-Type:
                                                                application/json'</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;</h4>
                                                    </div>
                                                    <p className="text-[12px]">conn.request("POST",
                                                        "/api/v1/autoWithdraw", payload, headers)</p>
                                                    <p className="text-[12px]">res = conn.getresponse()</p>
                                                    <p className="text-[12px]">data = res.read()</p>
                                                    <p className="text-[12px]">print(data.decode("utf-8"))</p><br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('right-withdraw', 'withdraw_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'right-withdraw' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                    {
                                        lang.title === 'PHP' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="withdraw_code">
                                                    <p className="text-[12px]">$client = new Client();</p>
                                                    <div>
                                                        <h4 className="text-[12px]">headers = [</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">'signatureToken:
                                                                eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNDlkNTdhMGIyNmRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw'</p>
                                                            <p className="text-[12px]">'Content-Type:
                                                                application/json'</p>
                                                        </div>
                                                        <h4 className="text-[12px]">];</h4>
                                                    </div>
                                                    <div>
                                                        <h4 className="text-[12px]">$body = '&#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">"currency":"6698aec7f188ae24c673da1c",</p>
                                                            <p className="text-[12px]">"amount":"0.01",</p>
                                                            <p className="text-[12px]">"address":"0xb6b5E02EBf4d20......3AfAbA034f2F8D8D07"</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;';</h4>
                                                    </div>
                                                    <p className="text-[12px]">$request = new Request('POST',
                                                        'https://doc-api.oppiwallet.com/api/v1/autoWithdraw',
                                                        $headers, $body);</p>
                                                    <p className="text-[12px]">$res =
                                                        $client->sendAsync($request)->wait();</p>
                                                    <p className="text-[12px]">echo $res->getBody();</p><br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('right-withdraw', 'withdraw_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'right-withdraw' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                    {
                                        lang.title === 'NodeJS' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="withdraw_code">
                                                    <p className="text-[12px]">const axios = require('axios');</p>
                                                    <div>
                                                        <h4 className="text-[12px]">let data =
                                                            JSON.stringify(&#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">"currency":"6698aec7f188ae24c673da1c",</p>
                                                            <p className="text-[12px]">"amount":"0.01",</p>
                                                            <p className="text-[12px]">"address":"0xb6b5E02EBf4d20......3AfAbA034f2F8D8D07"</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;);</h4>
                                                    </div>
                                                    <br />
                                                    <div>
                                                        <h4 className="text-[12px]">let config = &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">method: 'post',</p>
                                                            <p className="text-[12px]">maxBodyLength: Infinity,</p>
                                                            <p className="text-[12px]">url:
                                                                'https://doc-api.oppiwallet.com/api/v1/autoWithdraw',</p>
                                                            <div>
                                                                <h4 className="text-[12px]">headers: '&#123;</h4>
                                                                <div className="code ps-12">
                                                                    <p className="text-[12px]">'signatureToken':
                                                                        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw', </p>
                                                                    <p className="text-[12px]">'Content-Type':
                                                                        'application/json'</p>
                                                                </div>
                                                                <h4 className="text-[12px]">&#125;',</h4>
                                                            </div>
                                                            <p className="text-[12px]">data : data</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;;</h4>
                                                    </div>
                                                    <br />
                                                    <p className="text-[12px]">axios.request(config)</p>
                                                    <div>
                                                        <h4 className="text-[12px]">.then((response) => &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">console.log(JSON.stringify(response.data));</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;)</h4>
                                                    </div>
                                                    <div>
                                                        <h4 className="text-[12px]">.catch((error) => &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">console.log(error);</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;);</h4>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('right-withdraw', 'withdraw_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'right-withdraw' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="relative">
                                    <div className="border border-light-gray rounded-[8px]">
                                        <div className="flex items-center justify-between gap-[6px] p-[8px]">
                                            <h5 className="text-[16px]">Response</h5>
                                            <div className="relative">
                                                <div
                                                    className="cursor-pointer outline-none text-[14px] flex items-center gap-3"
                                                    onClick={() => { setOpen(true); setId('right-withdraw') }}>
                                                    <span className="flex gap-1 items-center">
                                                        {selectValue.icon} {selectValue.status}
                                                    </span>
                                                    <IconDown className="w-6 h-6 text-dark-gray" />
                                                </div>
                                                {
                                                    (id === 'right-withdraw' && open) &&
                                                    <div
                                                        className="bg-[#f1f1f1] border border-light-gray absolute bottom-0-0 w-full">
                                                        {
                                                            resList.map((data, index) =>
                                                                <span
                                                                    className="flex items-center gap-2 cursor-pointer text-[14px]"
                                                                    onClick={() => {
                                                                        setSelectValue(data);
                                                                        setOpen(false)
                                                                    }}>{data.icon} {data.status} </span>
                                                            )
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div
                                            className="px-[6px] ps-[10px] pb-[46px] xxl:max-w-[430px] max-w-full overflow-y-auto response-content" id='withdraw_res'>
                                            {selectValue.status === '200' && <div>
                                                <h4 className="text-12">&#123;</h4>
                                                <div className="code ps-12">
                                                    {/* <h4 className="text-12">"address":
                                                        "0xb6b5E02EBf4d20......3AfAbA0F8D8D07",</h4>
                                                    <h4 className="text-12">"amount": "0.01"</h4>
                                                    <h4 className="text-12">"tag": "2165464"</h4> */}
                                                    <h4 className="text-12">"message":
                                                        "Your withdraw request send successfully!"</h4>
                                                </div>
                                                <h4 className="text-12">&#125;</h4>
                                            </div>}
                                            {selectValue.status === '400' && <div>
                                                <h4 className="text-12">&#123;</h4>
                                                <div className="code ps-12">
                                                    <h4 className="text-12"> "message": "Error message"</h4>
                                                </div>
                                                <h4 className="text-12">&#125;</h4>
                                            </div>}
                                            <br />
                                        </div>
                                    </div>
                                    <div
                                        className="bg-[#f1f1f1] p-[10px] border border-light-gray border-t-0  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                        <div className="cursor-pointer w-fit flex" onClick={() => responseCopy('right-withdraw', 'withdraw_res')}>
                                            <IconCopy className='h-[24px] w-[24px] text-dark-gray' />
                                        </div>
                                        {(id === 'right-withdraw' && copyResponse) &&
                                            <p className="bg-gray text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="pb-[20px] pt-[20px] border-b border-gray" id="right-all-transactions" /* ref={el => (refs.right.current[4] = el)} */>
                                <h2 className="text-[16px] py-2 bg-black text-white rounded-lg px-4 mb-2"
                                    id="Auto-withdraw-collection">All Transactions</h2>
                                <p className="text-card text-[14px] font-600 uppercase">Language</p>
                                <div className="mt-[10px] mb-[20px] ">
                                    <div className="flex items-center gap-[4px] flex-wrap mb-[20px]">
                                        {
                                            language.map((data, index) =>
                                                <div
                                                    className={`flex items-center justify-center flex-col gap-[4px] rounded-[8px] border ${data.id === lang.id ? 'border-greenweb' : 'border-transparent'} hover:border-greenweb p-[6px_10px] min-w-[80px] cursor-pointer`}
                                                    onClick={() => setLang(data)}>
                                                    {data.icon}
                                                    <p className="text-[10px]">{data.title}</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                    {
                                        lang.title === 'Shell' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="all_transactions_code">
                                                    <p className="text-[12px]">curl --location
                                                        'https://doc-api.oppiwallet.com/api/transactions'</p>
                                                    <p className="text-[12px]">--header 'signatureToken:
                                                        eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw'</p>
                                                    <p className="text-[12px]">--header 'Content-Type:
                                                        application/json'</p>
                                                    <br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('right-all-transactions', 'all_transactions_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'right-all-transactions' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                    {
                                        lang.title === 'Python' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="all_transactions_code">
                                                    <p className="text-[12px]">import http.client'</p>
                                                    <p className="text-[12px]">import json</p><br />
                                                    <p className="text-[12px]">conn =
                                                        http.client.HTTPSConnection("doc-api.oppiwallet.com")</p>
                                                    <div>
                                                        <h4 className="text-[12px]">headers = &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">'signatureToken:
                                                                eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNDlkNTdhMGIyNmRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw'</p>
                                                            <p className="text-[12px]">'Content-Type:
                                                                application/json'</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;</h4>
                                                    </div>
                                                    <p className="text-[12px]">conn.request("GET",
                                                        "/api/transactions", headers)</p>
                                                    <p className="text-[12px]">res = conn.getresponse()</p>
                                                    <p className="text-[12px]">data = res.read()</p>
                                                    <p className="text-[12px]">print(data.decode("utf-8"))</p><br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('right-all-transactions', 'all_transactions_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'right-all-transactions' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                    {
                                        lang.title === 'PHP' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="all_transactions_code">
                                                    <p className="text-[12px]">$client = new Client();</p>
                                                    <div>
                                                        <h4 className="text-[12px]">headers = [</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">'signatureToken:
                                                                eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNDlkNTdhMGIyNmRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw'</p>
                                                            <p className="text-[12px]">'Content-Type:
                                                                application/json'</p>
                                                        </div>
                                                        <h4 className="text-[12px]">];</h4>
                                                    </div>
                                                    <p className="text-[12px]">$request = new Request('GET',
                                                        'https://doc-api.oppiwallet.com/api/transactions',
                                                        $headers);</p>
                                                    <p className="text-[12px]">$res =
                                                        $client->sendAsync($request)->wait();</p>
                                                    <p className="text-[12px]">echo $res->getBody();</p><br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('right-all-transactions', 'all_transactions_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'right-all-transactions' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                    {
                                        lang.title === 'NodeJS' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="all_transactions_code">
                                                    <p className="text-[12px]">const axios = require('axios');</p>
                                                    <br />
                                                    <div>
                                                        <h4 className="text-[12px]">let config = &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">method: 'get',</p>
                                                            <p className="text-[12px]">maxBodyLength: Infinity,</p>
                                                            <p className="text-[12px]">url:
                                                                'https://doc-api.oppiwallet.com/api/transactions',</p>
                                                            <div>
                                                                <h4 className="text-[12px]">headers: '&#123;</h4>
                                                                <div className="code ps-12">
                                                                    <p className="text-[12px]">'signatureToken':
                                                                        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw', </p>
                                                                    <p className="text-[12px]">'Content-Type':
                                                                        'application/json'</p>
                                                                </div>
                                                                <h4 className="text-[12px]">&#125;'</h4>
                                                            </div>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;;</h4>
                                                    </div>
                                                    <br />
                                                    <p className="text-[12px]">axios.request(config)</p>
                                                    <div>
                                                        <h4 className="text-[12px]">.then((response) => &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">console.log(JSON.stringify(response.data));</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;)</h4>
                                                    </div>
                                                    <div>
                                                        <h4 className="text-[12px]">.catch((error) => &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">console.log(error);</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;);</h4>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('right-all-transactions', 'all_transactions_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'right-all-transactions' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="relative">
                                    <div className="border border-light-gray rounded-[8px]">
                                        <div className="flex items-center justify-between gap-[6px] p-[8px]">
                                            <h5 className="text-[16px]">Response</h5>
                                            <div className="relative">
                                                <div
                                                    className="cursor-pointer outline-none text-[14px] flex items-center gap-3"
                                                    onClick={() => { setOpen(true); setId('right-all-transactions') }}>
                                                    <span className="flex gap-1 items-center">
                                                        {selectValue.icon} {selectValue.status}
                                                    </span>
                                                    <IconDown className="w-6 h-6 text-dark-gray" />
                                                </div>
                                                {
                                                    (id === 'right-all-transactions' && open) &&
                                                    <div
                                                        className="bg-[#f1f1f1] border border-light-gray absolute bottom-0-0 w-full">
                                                        {
                                                            resList.map((data, index) =>
                                                                <span
                                                                    className="flex items-center gap-2 cursor-pointer text-[14px]"
                                                                    onClick={() => {
                                                                        setSelectValue(data);
                                                                        setOpen(false)
                                                                    }}>{data.icon} {data.status} </span>
                                                            )
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div
                                            className="px-[6px] ps-[10px] pb-[46px] xxl:max-w-[430px] max-w-full overflow-y-auto response-content" id='all_transactions_res'>
                                            {selectValue.status === '200' && <div>
                                                <h4 className="text-12">[</h4>
                                                <div className="code ps-12">
                                                    <h4 className="text-12">&#123;</h4>
                                                    <div className="code ps-12">
                                                        <h4 className="text-12">"transactionId":
                                                            "03ea5d69-9ebf-4bfc-9b77-3aec6a6784d6",</h4>
                                                        <h4 className="text-12">"receiverAddress":
                                                            "0xAB710B08A....E02421Ff9Ef231Ee212",</h4>
                                                        <h4 className="text-12">"depositAddress":
                                                            "0xd5350F580....2bC925a07091b443732",</h4>
                                                        <h4 className="text-12">"orderId": "ORDER_1",</h4>
                                                        <h4 className="text-12">"tag": "",</h4>
                                                        <h4 className="text-12">"currency": "ETH",</h4>
                                                        <h4 className="text-12">"amount": "0.01",</h4>
                                                        <h4 className="text-12">"callbackStatus": 0,</h4>
                                                        <h4 className="text-12">"createdAt":
                                                            "2024-05-09T06:05:20.087Z",</h4>
                                                        <h4 className="text-12">"status": 0</h4>
                                                    </div>
                                                    <h4 className="text-12">&#125;</h4>
                                                </div>
                                                <h4 className="text-12">]</h4>
                                            </div>}
                                            {selectValue.status === '400' && <div>
                                                <h4 className="text-12">&#123;</h4>
                                                <div className="code ps-12">
                                                    <h4 className="text-12"> "message": "Error message"</h4>
                                                </div>
                                                <h4 className="text-12">&#125;</h4>
                                            </div>}
                                            <br />
                                        </div>
                                    </div>
                                    <div
                                        className="bg-[#f1f1f1] p-[10px] border border-light-gray border-t-0  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                        <div className="cursor-pointer w-fit flex" onClick={() => responseCopy('right-all-transactions', 'all_transactions_res')}>
                                            <IconCopy className='h-[24px] w-[24px] text-dark-gray' />
                                        </div>
                                        {(id === 'right-all-transactions' && copyResponse) &&
                                            <p className="bg-gray text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="pb-[20px] pt-[20px] border-b border-gray" id="right-single-transaction" /* ref={el => (refs.right.current[5] = el)} */>
                                <h2 className="text-[16px] py-2 bg-black text-white rounded-lg px-4 mb-2"
                                    id="Auto-withdraw-collection">Transaction</h2>
                                <p className="text-card text-[14px] font-600 uppercase">Language</p>
                                <div className="mt-[10px] mb-[20px] ">
                                    <div className="flex items-center gap-[4px] flex-wrap mb-[20px]">
                                        {
                                            language.map((data, index) =>
                                                <div
                                                    className={`flex items-center justify-center flex-col gap-[4px] rounded-[8px] border ${data.id === lang.id ? 'border-greenweb' : 'border-transparent'} hover:border-greenweb p-[6px_10px] min-w-[80px] cursor-pointer`}
                                                    onClick={() => setLang(data)}>
                                                    {data.icon}
                                                    <p className="text-[10px]">{data.title}</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                    {
                                        lang.title === 'Shell' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="single_transaction_code">
                                                    <p className="text-[12px]">curl --location
                                                        'https://doc-api.oppiwallet.com/api/transaction/03ea5d69-9ebf-4bfc-9b77-3aec6a6784d6'</p>
                                                    <p className="text-[12px]">--header 'signatureToken:
                                                        eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw'</p>
                                                    <p className="text-[12px]">--header 'Content-Type:
                                                        application/json'</p>
                                                    <br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('right-single-transaction', 'single_transaction_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'right-single-transaction' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                    {
                                        lang.title === 'Python' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="single_transaction_code">
                                                    <p className="text-[12px]">import http.client'</p>
                                                    <p className="text-[12px]">import json</p><br />
                                                    <p className="text-[12px]">conn =
                                                        http.client.HTTPSConnection("doc-api.oppiwallet.com")</p>
                                                    <div>
                                                        <h4 className="text-[12px]">headers = &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">'signatureToken:
                                                                eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNDlkNTdhMGIyNmRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw'</p>
                                                            <p className="text-[12px]">'Content-Type:
                                                                application/json'</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;</h4>
                                                    </div>
                                                    <p className="text-[12px]">conn.request("GET",
                                                        "/api/transaction/03ea5d69-9ebf-4bfc-9b77-3aec6a6784d6",
                                                        headers)</p>
                                                    <p className="text-[12px]">res = conn.getresponse()</p>
                                                    <p className="text-[12px]">data = res.read()</p>
                                                    <p className="text-[12px]">print(data.decode("utf-8"))</p><br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('right-single-transaction', 'single_transaction_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'right-single-transaction' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                    {
                                        lang.title === 'PHP' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="single_transaction_code">
                                                    <p className="text-[12px]">$client = new Client();</p>
                                                    <div>
                                                        <h4 className="text-[12px]">headers = [</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">'signatureToken:
                                                                eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNDlkNTdhMGIyNmRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw'</p>
                                                            <p className="text-[12px]">'Content-Type:
                                                                application/json'</p>
                                                        </div>
                                                        <h4 className="text-[12px]">];</h4>
                                                    </div>
                                                    <p className="text-[12px]">$request = new Request('GET',
                                                        'https://doc-api.oppiwallet.com/api/transaction/03ea5d69-9ebf-4bfc-9b77-3aec6a6784d6',
                                                        $headers);</p>
                                                    <p className="text-[12px]">$res =
                                                        $client->sendAsync($request)->wait();</p>
                                                    <p className="text-[12px]">echo $res->getBody();</p><br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('right-single-transaction', 'single_transaction_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'right-single-transaction' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                    {
                                        lang.title === 'NodeJS' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="single_transaction_code">
                                                    <p className="text-[12px]">const axios = require('axios');</p>
                                                    <br />
                                                    <div>
                                                        <h4 className="text-[12px]">let config = &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">method: 'get',</p>
                                                            <p className="text-[12px]">maxBodyLength: Infinity,</p>
                                                            <p className="text-[12px]">url:
                                                                'https://doc-api.oppiwallet.com/api/transaction/03ea5d69-9ebf-4bfc-9b77-3aec6a6784d6',</p>
                                                            <div>
                                                                <h4 className="text-[12px]">headers: '&#123;</h4>
                                                                <div className="code ps-12">
                                                                    <p className="text-[12px]">'signatureToken':
                                                                        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw', </p>
                                                                    <p className="text-[12px]">'Content-Type':
                                                                        'application/json'</p>
                                                                </div>
                                                                <h4 className="text-[12px]">&#125;'</h4>
                                                            </div>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;;</h4>
                                                    </div>
                                                    <br />
                                                    <p className="text-[12px]">axios.request(config)</p>
                                                    <div>
                                                        <h4 className="text-[12px]">.then((response) => &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">console.log(JSON.stringify(response.data));</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;)</h4>
                                                    </div>
                                                    <div>
                                                        <h4 className="text-[12px]">.catch((error) => &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">console.log(error);</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;);</h4>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('right-single-transaction', 'single_transaction_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'right-single-transaction' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="relative">
                                    <div className="border border-light-gray rounded-[8px]">
                                        <div className="flex items-center justify-between gap-[6px] p-[8px]">
                                            <h5 className="text-[16px]">Response</h5>
                                            <div className="relative">
                                                <div
                                                    className="cursor-pointer outline-none text-[14px] flex items-center gap-3"
                                                    onClick={() => { setOpen(true); setId('right-single-transaction') }}>
                                                    <span className="flex gap-1 items-center">
                                                        {selectValue.icon} {selectValue.status}
                                                    </span>
                                                    <IconDown className="w-6 h-6 text-dark-gray" />
                                                </div>
                                                {
                                                    (id === 'right-single-transaction' && open) &&
                                                    <div
                                                        className="bg-[#f1f1f1] border border-light-gray absolute bottom-0-0 w-full">
                                                        {
                                                            resList.map((data, index) =>
                                                                <span
                                                                    className="flex items-center gap-2 cursor-pointer text-[14px]"
                                                                    onClick={() => {
                                                                        setSelectValue(data);
                                                                        setOpen(false)
                                                                    }}>{data.icon} {data.status} </span>
                                                            )
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div
                                            className="px-[6px] ps-[10px] pb-[46px] xxl:max-w-[430px] max-w-full overflow-y-auto response-content" id='single_transaction_res'>
                                            {selectValue.status === '200' && <div>
                                                <h4 className="text-12">&#123;</h4>
                                                <div className="code ps-12">
                                                    <h4 className="text-12">"transactionId":
                                                        "03ea5d69-9ebf-4bfc-9b77-3aec6a6784d6",</h4>
                                                    <h4 className="text-12">"receiverAddress":
                                                        "0xAB710B08A....E02421Ff9Ef231Ee212",</h4>
                                                    <h4 className="text-12">"depositAddress":
                                                        "0xd5350F580....2bC925a07091b443732",</h4>
                                                    <h4 className="text-12">"orderId": "ORDER_1",</h4>
                                                    <h4 className="text-12">"tag": "",</h4>
                                                    <h4 className="text-12">"currency": "ETH",</h4>
                                                    <h4 className="text-12">"amount": "0.01",</h4>
                                                    <h4 className="text-12">"callbackStatus": 0,</h4>
                                                    <h4 className="text-12">"createdAt":
                                                        "2024-05-09T06:05:20.087Z",</h4>
                                                    <h4 className="text-12">"status": 0</h4>
                                                </div>
                                                <h4 className="text-12">&#125;</h4>
                                            </div>}
                                            {selectValue.status === '400' && <div>
                                                <h4 className="text-12">&#123;</h4>
                                                <div className="code ps-12">
                                                    <h4 className="text-12"> "message": "Error message"</h4>
                                                </div>
                                                <h4 className="text-12">&#125;</h4>
                                            </div>}
                                            <br />
                                        </div>
                                    </div>
                                    <div
                                        className="bg-[#f1f1f1] p-[10px] border border-light-gray border-t-0  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                        <div className="cursor-pointer w-fit flex" onClick={() => responseCopy('right-single-transaction', 'single_transaction_res')}>
                                            <IconCopy className='h-[24px] w-[24px] text-dark-gray' />
                                        </div>
                                        {(id === 'right-single-transaction' && copyResponse) &&
                                            <p className="bg-gray text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="pt-[20px]" id="right-get-balance" /* ref={el => (refs.right.current[5] = el)} */>
                                <h2 className="text-[16px] py-2 bg-black text-white rounded-lg px-4 mb-2"
                                    id="Auto-withdraw-collection">Get Balance</h2>
                                <p className="text-card text-[14px] font-600 uppercase">Language</p>
                                <div className="mt-[10px] mb-[20px] ">
                                    <div className="flex items-center gap-[4px] flex-wrap mb-[20px]">
                                        {
                                            language.map((data, index) =>
                                                <div
                                                    className={`flex items-center justify-center flex-col gap-[4px] rounded-[8px] border ${data.id === lang.id ? 'border-greenweb' : 'border-transparent'} hover:border-greenweb p-[6px_10px] min-w-[80px] cursor-pointer`}
                                                    onClick={() => setLang(data)}>
                                                    {data.icon}
                                                    <p className="text-[10px]">{data.title}</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                    {
                                        lang.title === 'Shell' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="single_transaction_code">
                                                    <p className="text-[12px]">curl --location
                                                        'https://doc-api.oppiwallet.com/api/v1/balance/6698aec7f188ae24c673da1c;'</p>
                                                    <p className="text-[12px]">--header 'signatureToken:
                                                        eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw'</p>
                                                    <p className="text-[12px]">--header 'Content-Type:
                                                        application/json'</p>
                                                    <br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('right-get-balance', 'single_transaction_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'right-get-balance' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                    {
                                        lang.title === 'Python' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="single_transaction_code">
                                                    <p className="text-[12px]">import http.client'</p>
                                                    <p className="text-[12px]">import json</p><br />
                                                    <p className="text-[12px]">conn =
                                                        http.client.HTTPSConnection("doc-api.oppiwallet.com")</p>
                                                    <div>
                                                        <h4 className="text-[12px]">headers = &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">'signatureToken:
                                                                eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNDlkNTdhMGIyNmRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw'</p>
                                                            <p className="text-[12px]">'Content-Type:
                                                                application/json'</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;</h4>
                                                    </div>
                                                    <p className="text-[12px]">conn.request("GET",
                                                        "/api/v1/balance/6698aec7f188ae24c673da1c", payload, headers)</p>
                                                    <p className="text-[12px]">res = conn.getresponse()</p>
                                                    <p className="text-[12px]">data = res.read()</p>
                                                    <p className="text-[12px]">print(data.decode("utf-8"))</p><br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('right-get-balance', 'single_transaction_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'right-get-balance' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                    {
                                        lang.title === 'PHP' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="single_transaction_code">
                                                    <p className="text-[12px]">$client = new Client();</p>
                                                    <div>
                                                        <h4 className="text-[12px]">headers = [</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">'signatureToken:
                                                                eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNDlkNTdhMGIyNmRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw'</p>
                                                            <p className="text-[12px]">'Content-Type:
                                                                application/json'</p>
                                                        </div>
                                                        <h4 className="text-[12px]">];</h4>
                                                    </div>
                                                    <p className="text-[12px]">$request = new Request('GET',
                                                        'https://doc-api.oppiwallet.com/api/v1/balance/6698aec7f188ae24c673da1c',
                                                        $headers);</p>
                                                    <p className="text-[12px]">$res =
                                                        $client->sendAsync($request)->wait();</p>
                                                    <p className="text-[12px]">echo $res->getBody();</p><br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('right-get-balance', 'single_transaction_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'right-get-balance' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                    {
                                        lang.title === 'NodeJS' &&
                                        <div className="relative">
                                            <div className="bg-black text-white pb-[46px] rounded-[6px]">
                                                <div
                                                    className="bg-[#58667c] p-[10px] rounded-[6px] rounded-b-none mb-[12px]">
                                                    <p className="text-[12px]">{lang.title}</p>
                                                </div>
                                                <div
                                                    className="px-[6px] ps-[10px] xxl:max-w-[430px] max-w-full overflow-y-auto code-content" id="single_transaction_code">
                                                    <p className="text-[12px]">const axios = require('axios');</p>
                                                    <br />
                                                    <div>
                                                        <h4 className="text-[12px]">let config = &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">method: 'get',</p>
                                                            <p className="text-[12px]">maxBodyLength: Infinity,</p>
                                                            <p className="text-[12px]">url:
                                                                'https://doc-api.oppiwallet.com/api/v1/balance/6698aec7f188ae24c673da1c',</p>
                                                            <div>
                                                                <h4 className="text-[12px]">headers: '&#123;</h4>
                                                                <div className="code ps-12">
                                                                    <p className="text-[12px]">'signatureToken':
                                                                        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmkiOiJjcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QiLCJpYXQiOjE3MTUxNTM2NTQsImV4cCI6MTcxNTE1MzcwOSwia2V5Ijoicmh0TmJELU9yTFQteFp3M3QtMkpFWnR4Iiwic2lnbmF0dXJlU3RyaW5nIjoiMWVjY2M1NDc0YWZiMzU4NDExNDEzNjBkOGQ0MDBlMWYxOWIzNRjYzVkM2YxNTRjNmQ5YjViNSJ9.JuF7d8Oc4EyMvm7s5g3CN9L9Wbl_TFI_yO8jt20dvHw', </p>
                                                                    <p className="text-[12px]">'Content-Type':
                                                                        'application/json'</p>
                                                                </div>
                                                                <h4 className="text-[12px]">&#125;'</h4>
                                                            </div>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;;</h4>
                                                    </div>
                                                    <br />
                                                    <p className="text-[12px]">axios.request(config)</p>
                                                    <div>
                                                        <h4 className="text-[12px]">.then((response) => &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">console.log(JSON.stringify(response.data));</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;)</h4>
                                                    </div>
                                                    <div>
                                                        <h4 className="text-[12px]">.catch((error) => &#123;</h4>
                                                        <div className="code ps-12">
                                                            <p className="text-[12px]">console.log(error);</p>
                                                        </div>
                                                        <h4 className="text-[12px]">&#125;);</h4>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                            <div
                                                className="bg-[#58667c] p-[10px]  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                                <div className="cursor-pointer w-fit flex" onClick={() => copyCode('right-get-balance', 'single_transaction_code')}>
                                                    <IconCopy className='h-[24px] w-[24px] text-white' />
                                                </div>
                                                {(id === 'right-get-balance' && clipboard) &&
                                                    <p className="bg-black text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="relative">
                                    <div className="border border-light-gray rounded-[8px]">
                                        <div className="flex items-center justify-between gap-[6px] p-[8px]">
                                            <h5 className="text-[16px]">Response</h5>
                                            <div className="relative">
                                                <div
                                                    className="cursor-pointer outline-none text-[14px] flex items-center gap-3"
                                                    onClick={() => { setOpen(true); setId('right-get-balance') }}>
                                                    <span className="flex gap-1 items-center">
                                                        {selectValue.icon} {selectValue.status}
                                                    </span>
                                                    <IconDown className="w-6 h-6 text-dark-gray" />
                                                </div>
                                                {
                                                    (id === 'right-get-balance' && open) &&
                                                    <div
                                                        className="bg-[#f1f1f1] border border-light-gray absolute bottom-0-0 w-full">
                                                        {
                                                            resList.map((data, index) =>
                                                                <span
                                                                    className="flex items-center gap-2 cursor-pointer text-[14px]"
                                                                    onClick={() => {
                                                                        setSelectValue(data);
                                                                        setOpen(false)
                                                                    }}>{data.icon} {data.status} </span>
                                                            )
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div
                                            className="px-[6px] ps-[10px] pb-[46px] xxl:max-w-[430px] max-w-full overflow-y-auto response-content" id='all_transactions_res'>
                                            {selectValue.status === '200' && <div>
                                                {/* <h4 className="text-12">[</h4>
                                                <div className="code ps-12"> */}
                                                <h4 className="text-12">&#123;</h4>
                                                <div className="code ps-12">
                                                    <h4 className="text-12">"balance":0.564333586,</h4>
                                                </div>
                                                <h4 className="text-12">&#125;</h4>
                                                {/* </div>
                                                <h4 className="text-12">]</h4> */}
                                            </div>}
                                            {selectValue.status === '400' && <div>
                                                <h4 className="text-12">&#123;</h4>
                                                <div className="code ps-12">
                                                    <h4 className="text-12"> "message": "Error message"</h4>
                                                </div>
                                                <h4 className="text-12">&#125;</h4>
                                            </div>}
                                            <br />
                                        </div>
                                    </div>
                                    <div
                                        className="bg-[#f1f1f1] p-[10px] border border-light-gray border-t-0  absolute bottom-0 w-full z-[1] rounded-[6px] rounded-t-none flex items-center gap-5">
                                        <div className="cursor-pointer w-fit flex" onClick={() => responseCopy('right-get-balance', 'single_transaction_res')}>
                                            <IconCopy className='h-[24px] w-[24px] text-dark-gray' />
                                        </div>
                                        {(id === 'right-get-balance' && copyResponse) &&
                                            <p className="bg-gray text-white text-[12px] rounded-[6px] py-[4px] px-[6px]">Copied</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DocumentationVersion2